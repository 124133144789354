@value unit: var(--ring-unit);

.title.title {
  margin-top: unit;
  margin-bottom: 0;

  font-size: var(--ring-font-size);
}

.warning {
  margin-bottom: calc(unit * 1.5);
}

.warning,
.description {
  line-height: var(--ring-line-height);
}
