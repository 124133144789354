@value unit: var(--ring-unit);

.textAreaWrapper {
  position: relative;
}

.textArea {
  min-height: calc(unit * 5.5);
  max-height: calc(unit * 10);

  background-color: var(--ring-content-background-color);

  font-family: var(--ring-font-family-monospace);
}

.notice {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
