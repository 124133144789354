@value unit: var(--ring-unit);

.container {
  position: relative;

  margin: calc(unit * -2) calc(unit * -4) 0 calc(unit * -4);
}

.main {
  composes: animation from '../SettingsSidebar/SettingsSidebar.css';

  display: flex;

  overflow: hidden;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;

  background-color: var(--ring-sidebar-background-color);
}
