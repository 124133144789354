@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.link {
  white-space: nowrap;

  &:hover {
    text-decoration: none;

    color: var(--ring-text-color);
  }
}

.jumpButton {
  padding: 0 unit;
}

.item {
  composes: item from '../ActionsDropdown/ActionsDropdown.css';
}

.hoverItem {
  composes: hoverItem from '../ActionsDropdown/ActionsDropdown.css';
}

.counter {
  composes: font-smaller-lower;

  margin-left: unit;

  color: var(--ring-main-color);
}

.loader {
  padding: unit unit calc(unit * 2);
}

.marker {
  display: inline-block;

  width: 6px;
  height: 6px;
  margin-left: unit;

  vertical-align: 1px;

  border-radius: 50%;
  background-color: var(--ring-main-color);
}
