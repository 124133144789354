@value unit: var(--ring-unit);

.healthItems {
  & :global(.healthItemIndicator) {
    margin-left: calc(unit * -0.5);

    padding: 0;

    border: none;
    background: none;
    box-shadow: none;

    font-size: var(--ring-font-size-smaller);

    line-height: inherit;
  }

  & :global(.info.info) {
    color: var(--ring-secondary-color);
  }

  & :global(.healthItemIndicator):focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--ring-border-hover-color);
  }

  & :global(.healthItemIndicator):hover,
  & :global(.healthItemIndicator):hover *,
  & :global(.healthItemIndicator):focus,
  & :global(.healthItemIndicator):focus * {
    color: var(--ring-link-hover-color);
    fill: currentColor;
  }

  & :global(.healthItemIndicator):active,
  & :global(.healthItemIndicator):active * {
    color: var(--ring-main-color);
    fill: currentColor;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & :global(.healthItemIndicator):hover :global(.info) {
    color: inherit;
  }
}
