@value unit: var(--ring-unit);

.statuses > * {
  margin-right: calc(unit * 1.5);

  white-space: nowrap;

  color: var(--ring-text-color);
}

.headingLink,
.text {
  color: var(--ring-text-color);
}

.icon {
  margin-right: calc(unit / 2);

  &.failed.failed {
    color: var(--ring-icon-error-color);
  }

  &.running {
    color: var(--ring-icon-secondary-color);
  }
}

.successful {
  color: var(--ring-success-color);

  & .icon {
    color: var(--ring-icon-success-color);
  }
}

.failed {
  color: var(--ring-error-color);

  & .icon {
    color: var(--ring-icon-error-color);
  }
}

.overtimeIcon {
  color: var(--ring-icon-secondary-color);
}
