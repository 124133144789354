.overlay {
  position: absolute;

  z-index: 3;
  top: 0;

  width: 100%;
  height: 100%;

  opacity: 0.4;

  background-color: var(--ring-content-background-color);
}
