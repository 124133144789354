.statusColumn {
  grid-column-start: status-start;
}

.problemNameColumn {
  grid-column-start: problem-name-start;
}

.assignedColumn {
  grid-column-start: assigned-start;
}

.actionsColumn {
  grid-column-start: actions-start;
}
