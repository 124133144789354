@value unit: var(--ring-unit);

.activate {
  margin-left: unit;

  color: #008eff;

  &.lineBreak {
    display: block;

    margin-left: 0;
  }
}
