@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  align-items: baseline;
}

.popup {
  max-width: 500px;
  padding: calc(unit * 2);
  padding-bottom: unit;
}

.visibleBranchLabel {
  margin-right: unit;
}

.branchLabel {
  margin-right: unit;
  margin-bottom: unit;
}

.anchor {
  white-space: nowrap;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
