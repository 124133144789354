@value unit: var(--ring-unit);

.successStatus {
  color: var(--ring-success-color);
}

.failedStatus {
  color: var(--ring-error-color);
}

.unknownStatus {
  color: var(--ring-secondary-color);
}

.muted {
  color: var(--ring-secondary-color);
}

.icon {
  margin-left: calc(unit / 2);
  padding: 0;

  color: var(--ring-secondary-color);
}
