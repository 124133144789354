@value unit: var(--ring-unit);

.timeline {
  display: flex;

  height: calc(unit * 3);
}

.loading {
  position: relative;

  overflow: hidden;

  width: 100%;
  height: 100%;

  padding-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

@keyframes line-animation {
  0% {
    transform: translateX(-90%);
  }

  100% {
    transform: translateX(-5%);
  }
}

.loadingLine {
  transform: translateX(-90%);
  animation: line-animation 15s ease-out 1s forwards;
}
