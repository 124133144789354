@value unit: var(--ring-unit);

.container {
  display: flex;

  padding-bottom: calc(unit * 4);
}

.wrapper {
  min-width: calc(unit * 100);
  max-width: calc(unit * 180);
  margin: auto;
}

.title.title {
  margin: calc(unit * 4) 0 calc(unit * 2);
  padding: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.runs {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content);
  grid-column-gap: calc(unit * 2);

  justify-content: space-between;
}

.columnHeader {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.startDate {
  text-align: end;
}

.duration {
  text-align: end;
}

.columnHeadersUnderline {
  margin-top: unit;

  border-bottom: 1px solid var(--ring-line-color);
  grid-column: 1/-1;
}
