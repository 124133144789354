@value unit: var(--ring-unit);

.edit {
  margin-right: calc(unit * -1);

  transition: opacity var(--ring-ease);

  opacity: 0;
}

.container {
  display: flex;
  column-gap: unit;

  cursor: pointer;

  &:hover .edit {
    opacity: 1;
  }

  &:hover .editButtonIcon {
    color: var(--ring-link-hover-color);
  }

  & div:nth-child(2) {
    flex-grow: 1;
  }
}

.defaultBranch {
  display: flex;
}

.branchRules {
  display: flex;
  align-items: baseline;

  margin-top: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
