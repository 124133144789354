.agents {
  padding: 0 var(--ring-unit) 1px;
}

.placeholder {
  color: var(--ring-secondary-color);
}

.loader {
  margin: -1px 0 0 var(--ring-unit);
}
