@value unit: var(--ring-unit);

.list {
  position: relative;

  display: grid;
  align-items: baseline;

  justify-content: space-between;

  grid-template-columns: auto 1fr auto auto;
  grid-column-gap: calc(unit * 4);
}
