@value unit: var(--ring-unit);

.dots {
  position: relative;

  overflow: hidden;
  flex: 1 0 0;

  width: calc(unit * 1.5);
}

.dots::after {
  position: absolute;
  top: 0;
  left: 0;

  content: '';
  animation: dots-animation 2s linear infinite;
}

@keyframes dots-animation {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '...';
  }
}
