@value unit: var(--ring-unit);

.main {
  composes: animation from '../components/common/SettingsSidebar/SettingsSidebar.css';

  display: flex;
  overflow: auto;

  flex: 1;

  flex-direction: column;
}

.content {
  display: flex;
  align-self: baseline;
  flex: 1 1;
  flex-direction: column;

  box-sizing: border-box;
  min-width: 100%;
}

.chart {
  padding: 0 calc(unit * 4);
}

.openSidebar {
  & .header {
    padding-right: calc(unit * 2.5);
  }

  & .toolbar,
  & .chart {
    padding-right: calc(unit * 2.5);
  }
}

