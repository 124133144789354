@value unit: var(--ring-unit);

.container {
  display: flex;
  gap: calc(unit / 2);

  align-items: center;

  height: calc(unit * 2.25);

  color: var(--ring-icon-color);
}

.iconButton {
  padding: 0;
}

.popup {
  display: flex;
  overflow: hidden;

  width: calc(unit * 50);

  & > div {
    width: 100%;
  }
}

.content {
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  height: 100%;
  max-height: 400px;
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
  gap: calc(unit * 2);
}
