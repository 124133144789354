@value unit: var(--ring-unit);

.caption {
  box-sizing: border-box;
  max-width: 100%;
  margin-left: calc(unit * -0.5);
}

.caption.caption {
  margin-right: calc(unit * 2);
}

.container {
  display: flex;
  align-items: baseline;

  line-height: var(--ring-line-height-lowest);
}

.icon {
  margin-right: calc(unit * 1.5);
  margin-left: calc(unit / 2);
}

.title {
  margin: calc(unit / 4) 0 0;

  font-weight: normal;

  line-height: var(--ring-line-height-lowest);
}

.description {
  margin: calc(unit / 2) 0 calc(unit * 1.5);

  color: var(--ring-text-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}
