@value ellipsis from '../../../ring-globals.css';

.inline {
  composes: ellipsis;
}

.buildPath {
  display: inline;

  line-height: var(--ring-line-height-lower);

  & > * {
    display: inline;
  }
}

.buildPathLink {
  color: inherit;
}
