.container {
  display: flex;
  overflow: hidden;

  min-width: 0;

}

.innerContainer {
  display: flex;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 1;

  white-space: nowrap;
}

.projectName {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;

  min-width: 17px;
}

.buildTypeName {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;

  min-width: 17px;
}

.ellipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow-wrap: normal;
}

.nameInner {
  overflow: hidden;

  min-width: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
}
