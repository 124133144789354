.botton {
  position: absolute;
  right: 0;

  color: var(--ring-error-color);
}

.confirm {
  z-index: 200;
}
