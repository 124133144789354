@value unit: var(--ring-unit);

.name {
  width: 100%;
  margin-bottom: unit;
}

.maxAgents {
  width: calc(15 * unit);
  margin-bottom: calc(-1.5 * unit);
}

.maxAgentsInput::-webkit-outer-spin-button,
.maxAgentsInput::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.maxAgentsInput[type='number'] {
  -moz-appearance: textfield;
}
