@value awaits-color: #cc8b00;
@value refuse-color: var(--ring-error-color);
@value approved-color: var(--ring-success-color);
@value unit: var(--ring-unit);

.approveButton {
  margin-top: calc(unit / 2);
  margin-right: unit;

  font-size: var(--ring-font-size);
}
