@value circle from '@jetbrains/ring-ui/components/radio/radio.css';
@value font-smaller from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.editForm {
  width: calc(unit * 65);
}

.form {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  min-height: 0;
}

.description {
  composes: font-smaller;

  margin-top: 0;
  margin-bottom: 0;

  color: var(--ring-secondary-color);
}

.headerHint {
  composes: headerHint from '../Listings/Rules/CleanupForm/CleanupForm.css';
}

.section {
  margin: 0 0 calc(unit * 2);
  padding: 0;

  border: none;
}

.legend {
  padding: 0;
}

.radioWrapper {
  display: flex;
  align-items: baseline;
}

.radioGroup {
  flex-shrink: 0;

  min-width: calc(unit * 16);
}

.radio {
  align-items: baseline;

  box-sizing: border-box;
  height: calc(unit * 3);
}

.radio .circle {
  align-self: stretch;
}

.inputs {
  display: flex;
  flex-direction: column;

  margin-top: unit;
  margin-left: calc(unit * 3);
}

.input {
  composes: input from '../Listings/Rules/CleanupForm/CleanupForm.css';
}

.inputGroup {
  display: flex;
  align-items: baseline;

  margin: calc(unit / 4) 0;

  & .input {
    width: calc(unit * 10);

    text-align: right;
  }

  & .label {
    display: inline-block;
    flex-shrink: 0;

    width: calc(unit * 13);
    margin: calc(unit / 2) 0;

    vertical-align: top;
  }

  & .postLabel {
    & input {
      margin-right: unit;
    }
  }

  & .inputDescription {
    display: inline;

    color: var(--ring-secondary-color);
  }
}

.textareaWrapper {
  display: inline-block;

  width: 100%;

  resize: vertical;
}

.defaultPolicy {
  margin: 0;
}

.error {
  display: inline-block;

  width: calc(unit * 53);

  color: var(--ring-error-color);
}

.panel {
  display: flex;
  align-items: baseline;
}

.title.title {
  font-size: inherit;
  line-height: inherit;
}
