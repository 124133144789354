@value unit: var(--ring-unit);

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.toggle {
  display: flex;
  align-items: center;
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
