@value unit: var(--ring-unit);

.wrapper {
  position: relative;

  display: flex;

  overflow: hidden;
  flex-direction: row;

  justify-content: space-between;
}

.title {
  overflow: hidden;

  letter-spacing: 2px;
  text-overflow: ellipsis;

  font-size: var(--ring-font-size-smaller);

  line-height: calc(var(--ring-unit)*3);
}

.popup {
  padding: unit;
}

.popupHeader {
  margin-top: calc(2 * unit);

  font-size: calc(3 * unit);
}
