.progres {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  & > .progresBar {
    box-sizing: border-box;

    height: 100%;

    border-bottom: 1px solid transparent;

    background-clip: padding-box;

    &:last-child {
      border: 0;
    }
  }
}

/* stylelint-disable-next-line */
.progress-success {
  background-color: rgba(var(--ring-icon-success-components), 0.12);
}

/* stylelint-disable-next-line */
.progress-failure {
  background-color: rgba(var(--ring-icon-error-components), 0.12);
}
