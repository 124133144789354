@value font-smaller from '../../../ring-globals.css';

.compatibleAgents {
  composes: font-smaller;
}

.anchor {
  height: 20px;

  padding: 0 4px;

  line-height: 20px;
}

.secondary {
  color: var(--ring-secondary-color);
}
