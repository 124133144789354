.loader {
  composes: loader from '../ProjectPage/ProjectPage.css';
}

.morePopupItemClassName {
  composes: morePopupItemClassName from '../ProjectPage/ProjectPage.css';
}

.fullHeight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
