@value unit: var(--ring-unit);

.header {
  display: flex;
  align-items: baseline;
}

.title {
  display: flex;
  align-items: baseline;
}

.title.title {
  margin: 0;
  margin-right: auto;
  margin-bottom: unit;
  padding: 0;

  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}
