@value font, font-smaller from '../../../../ring-globals.css';

.agentPoolLink {
  composes: font;

  color: var(--ring-link-color);
}

.secondary {
  composes: font-smaller;

  color: var(--ring-secondary-color);

  & .icon {
    color: var(--ring-icon-secondary-color);
  }
}

.onlyText {
  overflow: hidden;
}
