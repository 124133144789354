@value unit: var(--ring-unit);

.line {
  margin-bottom: calc(1.5 * unit);
}

.statusUp {
  color: var(--ring-success-color);
}

.statusDown {
  color: var(--ring-error-color);
}

.commentWrapper {
  font-size: calc(1.5 * unit);
  line-height: calc(2 * unit);
}

.comment {
  display: inline-block;
}

.switchTime {
  font-weight: bold;
}
