@value description from '@jetbrains/ring-ui/components/message/message.css';
@value unit: var(--ring-unit);

.buttonGroup.invisible {
  display: flex;
  visibility: hidden;
  overflow: hidden;

  width: 0;
  margin: 0;
}

.disabledReasonPopup {
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.disabledIcon.disabledIcon {
  color: var(--ring-icon-disabled-color);
}

.popup {
  padding: unit calc(unit * 2);

  white-space: nowrap;

  border: 1px solid rgba(var(--ring-borders-components), .5);

  background-color: var(--ring-content-background-color);
}

.popupTail {
  border-bottom-color: rgba(var(--ring-borders-components), .5);
  border-left-color: rgba(var(--ring-borders-components), .5);
  background-color: var(--ring-content-background-color);
}

.popup .description {
  margin: 0;
}
