@import '@jetbrains/ring-ui/components/global/variables.css';

@value error as error_ from '../../TwoFactorAuthPages.css';
@value unit: var(--ring-unit);

/* pure brandbook colors */
@value tc-purple: #9135e0;
@value tc-blue: #05c1fd;
@value tc-green: #18d68c;

.confirmationForm {
  margin-top: calc(unit * 2);
}

.delete {
  margin-top: calc(unit / 2);
}

.error {
  composes: error_;
}

.heading {
  margin-top: calc(4 * unit);

  font-size: inherit;
  font-weight: bold;
}

.greenHeading {
  composes: heading;

  color: var(--ring-success-color);
}

.configurationForm {
  margin-top: -12px;
}

.popup {
  padding: unit calc(2 * unit);
}
