.search {
  flex-shrink: 0;

  width: 230px;
  margin-right: calc(var(--ring-unit) * 2);
}

@media (min-width: 1400px) {
  .search {
    width: 300px;
  }
}

@media (min-width: 1600px) {
  .search {
    width: 400px;
  }
}

