@value unit: var(--ring-unit);

.cell {
  box-sizing: border-box;
  height: calc(unit * 4);
  padding-top: unit;
}

.startDate {
  text-align: end;
}

.duration {
  text-align: end;
}

.status,
.number {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.estimate {
  color: var(--ring-secondary-color);
}

.stop {
  margin-right: calc(unit * -1);
}

.branchLabel {
  font-weight: bold;
}
