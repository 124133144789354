@value unit: var(--ring-unit);

.domainInfo {
  margin-bottom: unit;
}

.domainError {
  color: var(--ring-error-color);

  & > span {
    margin-right: unit;

    color: var(--ring-icon-error-color);
  }
}

.terms {
  margin-bottom: calc(unit * 1.5);
}

.errorBlock {
  display: flex;
  flex-direction: column;
  row-gap: unit;
}

.termsContainer {
  transition: opacity var(--ring-ease);
}

.overlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}
