@value unit var(--ring-unit);

.dialog {
  width: min-content;
  min-width: 580px;
}

.wrapper {
  padding-top: calc(unit * 3);
  padding-bottom: unit;
}

.overlay {
  z-index: 6;

  padding: 0;
}
