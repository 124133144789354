@value unit: var(--ring-unit);

.header {
  max-width: var(--tc-content-width);
  margin-bottom: calc(unit * 2);
}

.heading {
  composes: arrowWrapper from '../../../common/ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.css';
}

.heading.heading {
  margin: 0;
}

.heading.projectArchived .title {
  color: var(--ring-secondary-color);

  font-weight: normal;
}

.star {
  margin-left: -2px;

  vertical-align: 3px;
}

.branchFilter {
  flex: 0 0 auto;

  font-size: var(--ring-font-size-smaller);

  font-weight: normal;
  line-height: var(--ring-line-height-lowest);
}

.links {
  display: flex;
  align-items: baseline;

  min-height: calc(unit * 3);
}

.linksSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: unit;
  }

  & > *:first-child {
    margin-right: 0;
  }
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}

.subHeader {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-top: unit;
}

.branchFilter,
.createProject {
  margin-right: unit;
}
