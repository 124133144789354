@value unit: var(--ring-unit);

.success {
  color: var(--ring-success-color);
}

.failure {
  color: var(--ring-error-color);
}

.queued {
  color: var(--ring-secondary-color);
}

.canceled {
  color: var(--ring-secondary-color);
}

.icon {
  margin-right: calc(unit / 2);
}
