@value unit: var(--ring-unit);

.page {
  min-height: calc(unit * 50);
  margin: calc(unit * -1) calc(unit * -4) 0;
}

.log {
  height: 100%;
}
