.container {
  white-space: nowrap;
}

.expandHandleContainer {
  margin-left: var(--ring-unit);
}

.icon {
  padding: 0;
}
