.visibilityDetector {
  position: absolute;
  top: var(--absolute-child-top);
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
  grid-column: start / end;
  grid-row: var(--row) / span var(--row-span);
}
