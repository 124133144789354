@value unit: var(--ring-unit);

.header {
  padding: unit calc(unit * 2) 0 calc(unit * 2);

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-content-background-color);

  &.extraPadding {
    padding-right: calc(unit * 4);
    padding-left: calc(unit * 4);
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-bottom: calc(unit * 2);
  column-gap: calc(unit * 2);
  row-gap: unit;
}

.rightControls {
  text-align: right;

  font-size: var(--ring-font-size-smaller);
}

.settingsAction {
  margin-left: calc(unit * 1.5);
  padding: 0;

  color: var(--ring-secondary-color);
}
