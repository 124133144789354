@value unit: var(--ring-unit);

.item {
  position: relative;

  flex-direction: column;
}

.icon {
  margin-top: 1px;
}

.name {
  display: inline-block;

  width: calc(100% - 30px);

  margin: 0;
}

.templateList,
.diskUsage {
  width: 100%;
  margin-top: 0;

  font-size: var(--ring-font-size-smaller);
}

.link {
  padding-right: unit;
}

.usages {
  position: absolute;
  top: 0;
  right: calc(unit * 7);
}
