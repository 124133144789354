@value unit: var(--ring-unit);

.wrapper {
  position: relative;
}

.edges {
  position: relative;
  fill: transparent;
  stroke: var(--ring-icon-color);

  pointer-events: none;
}
