.restoreJobIcon {
  color: var(--ring-main-color);
}

.restoreJobIcon.disabled {
  color: var(--ring-disabled-color);
}

.restoreJob {
  white-space: nowrap;

  color: var(--ring-main-color);

  &:hover .restoreJobIcon {
    color: var(--ring-link-hover-color)
  }
}
