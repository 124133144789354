.couter {
  padding-right: 0;

  padding-left: 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.runningCount {
  color: var(--ring-success-color);
}

.couter:hover .runningCount {
  color: inherit;
}
