@value ellipsis from '../../../../ring-globals.css';

.copyButton {
  height: unset;
  padding-right: 0;

  opacity: 0;

  line-height: var(--ring-line-height);

  &:focus {
    opacity: 1;
  }
}

.wrapper {
  composes: ellipsis;

  display: inline-flex;

  font-family: var(--ring-font-family-monospace);

  &:hover .copyButton {
    opacity: 1;
  }
}
