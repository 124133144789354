@value unit: var(--ring-unit);

.container {
  margin-bottom: unit;

  text-align: right;
}

.actions {
  display: flex;
}

.button {
  margin-left: unit;
}
