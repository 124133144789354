@value jobWidth, jobHeight from '../JobTile.css';

.wrapper {
  overflow: hidden;

  width: 0;
  height: 0;

  transition-timing-function: ease-in;
  transition-duration: 200ms;
  transition-property: height, width;
}

.startAnimation {
  width: jobWidth;
  height: jobHeight;
}
