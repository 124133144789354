@value unit: var(--ring-unit);

.list {
  display: contents;

  margin-bottom: calc(unit * 2);
}

.withOverlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.content {
  margin-bottom: unit;

  padding: 0;
}

.wrapper .heading {
  margin-bottom: unit;
}

.heading.heading {
  font-size: inherit;
  line-height: inherit;
}

.grid {
  --status-column: [start status-start] max-content;
  --duration-column: [duration-start] max-content;
  --build-column: [build-start] max-content;
  --build-details-column: [build-details-start] auto;
  --changes-column: [changes-start] minmax(auto, 150px);
  --agent-column: [agent-start] minmax(auto, 150px);
  --changes-agent-column: [changes-start agent-start] minmax(auto, 150px);
  --start-date-column: [start-date-start] max-content;
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: start;
  grid-column-gap: calc(unit * 2);
  grid-template-columns: var(--status-column) var(--duration-column) var(--build-column) var(--build-details-column) var(--changes-column) var(--agent-column) var(--start-date-column) var(--actions-column) [end];
}

@media (max-width: 1400px) {
  .grid {
    grid-template-columns: var(--status-column) var(--duration-column) var(--build-column) var(--build-details-column) var(--changes-agent-column) var(--start-date-column) var(--actions-column) [end];
  }
}
