@value unit: var(--ring-unit);

.postLink {
  display: flex;
  align-items: center;

  min-width: calc(unit * 2);
  margin-left: calc(unit / 2);
}

.container {
  display: flex;
  align-items: center;
}

.counter {
  margin-left: calc(unit / 2);

  color: var(--ring-main-color);
}

.progressPlaceholder {
  width: calc(unit * 2);
}

.link {
  color: var(--ring-text-color);
}

.projectsPopup {
  top: unset !important;

  display: flex;

  margin-left: 6px;
  padding-right: calc(unit / 2);
  padding-left: calc(unit / 2);

  cursor: pointer;

  transition: color var(--ring-fast-ease);

  color: var(--ring-text-color);
  border-left: 1px solid var(--ring-text-color);
}

.projectsPopup:hover {
  transition: none;

  color: var(--ring-icon-hover-color);
}

.projectsPopupContainer {
  padding-left: 0;
}

.administration {
  padding: 0 unit;
}

.addProject.addProject {
  margin-left: unit;

  color: var(--ring-main-color);

  &:hover {
    color: var(--ring-icon-hover-color);
  }
}

.progressBar.progressBar {
  stroke: var(--ring-main-color);
}
