@value unit: var(--ring-unit);

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}

.separator {
  margin: 0 unit;

  color: var(--ring-line-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.highlight {
  color: var(--ring-text-color)
}
