.statusColumn {
  grid-column-start: status-start;

  display: flex;

  white-space: nowrap;
}

.projectScopeColumn {
  grid-column-start: project-scope-start;
}

.changesColumn {
  grid-column-start: changes-start;
}

.startedColumn {
  grid-column-start: started-start;
}

.actionsColumn {
  grid-column-start: actions-start;
}
