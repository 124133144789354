.item {
  display: flex;

  overflow: hidden;

  white-space: nowrap;
}

.content {
  overflow: hidden;
  flex: 1 1 auto;
}

.expandHandle {
  flex: 0 0 auto;
}
