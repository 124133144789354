@value font-smaller, ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.systemProblems {
  composes: font-smaller;

  display: flex;

  align-items: baseline;

  white-space: nowrap;
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-error-color);
}

.text {
  composes: ellipsis;
}

.countProblems {
  flex: 0 0 auto;

  margin-left: calc(unit / 4);
}

.showDetails {
  height: auto;
  margin-right: calc(unit * -1);
  padding: 0 unit;

  line-height: inherit;
}

.dialogContent {
  width: auto;
  min-width: calc(unit * 58);
  max-width: calc(unit * 108);
}

.scrolledHeaderIcon {
  margin-right: 1px;
}

.item {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.item:not(:first-child) {
  padding-top: calc(unit * 1.5);

  box-shadow: 0 1px var(--ring-line-color) inset;
}

.item:not(:last-child) {
  padding-bottom: calc(unit * 4);
}

.itemHeadingWrapper {
  display: flex;
  align-items: baseline;

  margin-bottom: 6px;
}

.solutionLink {
  height: auto;
  margin: 0 calc(unit * -2) 0 auto;

  line-height: inherit;
}

.description {
  composes: font-smaller;

  color: var(--ring-error-color);

  font-family: var(--ring-font-family-monospace);
}

.stacktraceHeading {
  margin: calc(unit * 2) 0 0 calc(unit * -2);
}

.chevronIcon {
  margin-right: 2px;
}

.stacktrace {
  composes: font-smaller;

  margin-top: 6px;

  font-family: var(--ring-font-family-monospace);
}
