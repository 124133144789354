@value unit: var(--ring-unit);

.nameSection {
  composes: section from '../../../SettingsBlock/SettingsBlock.css';
}

.heading {
  display: flex;
  align-items: center;
  column-gap: unit;

  cursor: pointer;
}

h2.name {
  overflow: hidden;

  margin: 0;
  padding: 0;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: var(--ring-font-size);

  font-weight: 600;
}

.heading:hover .name,
.heading:hover .editIcon {
  color: var(--ring-link-hover-color);
}


.edit {
  margin-right: calc(unit * -1);

  transition: opacity var(--ring-ease);

  opacity: 0;
}

.nameSection:hover .edit {
  opacity: 1;
}
