@value unit: var(--ring-unit);

.main {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;

  height: 100%;

  line-height: var(--ring-line-height-taller);
}

.editor {
  flex: 1 1 auto;
}
