@value unit: var(--ring-unit);

.wrapper {
  padding-top: calc(unit * 2);
  padding-bottom: calc(unit * 2);
}

.buttons {
  margin-bottom: unit;
}

.loader {
  margin-top: unit;
}
