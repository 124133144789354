@value font-smaller from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.content {
  flex: 1 0 auto;
}

.emptyHistory {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: var(--ring-secondary-color);
}

.investigation {
  margin-bottom: calc(unit * 1.5);

  &:last-child {
    margin-bottom: 0;
  }
}

.investigationHeader {
  line-height: var(--ring-line-height);
}

.user {
  font-weight: bold;
}

.timestamp {
  composes: font-smaller;

  color: var(--ring-secondary-color);
}

.investigationContent {
  display: flex;
  flex-direction: column;
}

.resolveMethod {
  color: var(--ring-secondary-color);
}

.comment {
  overflow-wrap: break-word;

  white-space: normal;
}
