@value unit: var(--ring-unit);

.logMessageLoading {
  height: var(--ring-line-height-lower);

  padding-top: 1px;
  padding-left: calc(unit * 3.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.runningText {
  display: inline-block;

  margin-left: calc(unit / 2);

  color: var(--ring-text-color);
}
