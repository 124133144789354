@value unit: var(--ring-unit);

.statusColumn,
.projectScopeColumn,
.changesColumn,
.startedColumn,
.actionsColumn {
  padding: unit 0;
}

.statusColumn {
  z-index: 2;

  display: flex;

  white-space: nowrap;
  grid-column-start: status-start;
  grid-row-start: var(--row);
}

.projectScopeColumn {
  overflow: hidden;
  align-items: baseline;
  grid-column-start: project-scope-start;
  grid-row-start: var(--row);
}

.changesColumn {
  z-index: 2;

  grid-column-start: changes-start;
  grid-row-start: var(--row);
}

.startedColumn {
  z-index: 2;

  grid-column-start: started-start;
  grid-row-start: var(--row);
}

.actionsColumn {
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.projectScope {
  display: flex;
  gap: unit;

  padding: calc(unit * 0.25) 0;
}

.status {
  display: flex;
  align-items: center;

  color: var(--ring-error-color);
}

.status,
.started {
  padding: calc(unit * 0.5) 0;
}

.buildChangesDropdown {
  padding-top:  calc(unit * 0.25);
}

.icon {
  margin-right: calc(unit / 2);
}

.buildPathContainer {
  display: flex;
  overflow: hidden;
  align-items: center;

  color: var(--ring-link-color);
  gap: unit;
}

.buildStatus {
  flex-shrink: 0;

  color: var(--ring-link-color);
}

.buildPathAndStatus {
  overflow: hidden;
  flex-wrap: nowrap;

  padding: calc(unit * 0.5 - 1) 0 calc(unit * 0.5 - 1);
}

.buildNumber {
  display: flex;
  gap: calc(unit * 0.75);
}

.buildLogIcon {
  color: var(--ring-icon-color);
}

.buildPath {
  overflow: hidden;

  white-space: nowrap;
}

.row:not(:last-child) {
  &::before {
    position: absolute;

    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    border-bottom: 1px solid var(--ring-line-color);

    grid-row: var(--row) / span 1;
  }
}
