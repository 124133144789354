@value unit: var(--ring-unit);

.title.title {
  margin-right: auto;

  color: var(--ring-heading-color);

  font-size: inherit;

  font-weight: bold;
  line-height: inherit;
}

.header {
  composes: row from '../../../../../common/CollapsibleLine/CollapsibleLine.css';

  justify-content: flex-start;
}

.headless {
  display: none;
}

.line {
  composes: line from '../../../../../common/CollapsibleLine/CollapsibleLine.css';

  margin-top: 0;
  margin-bottom: 0;
}

.arrow {
  composes: arrow from '../../../../../common/CollapsibleLine/CollapsibleLine.css';
}

.content {
  margin-bottom: calc(unit * 3);
}

.nonBuilt {
  &:hover {
    background: var(--ring-hover-background-color);
  }
}

.container {
  padding: 0 calc(unit * 2);
}
