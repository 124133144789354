@value unit: var(--ring-unit);

.secret {
  display: flex;
  justify-content: space-between;

  margin-bottom: calc(unit * 1.5);

  &:hover .secretActions > * {
    opacity: 1;
  }
}

.restoreButton,
.removeButton,
.editButton {
  height: auto;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.secretInputWrapper {
  position: relative;

  display: flex;

  height: calc(unit * 3);
  margin: -2px 0;
}

.popupError {
  width: calc(100% - 60px);
}

.secretInputWrapperDeleted {
  color: var(--ring-secondary-color);
  -webkit-text-fill-color: var(--ring-secondary-color);
}

.secretInputWrapperDeleted .secretName {
  text-decoration: line-through;

  background-color: transparent;
}

.secretValue {
  display: flex;

  line-height: 16px;
  gap: 0 3px;
}

.inputSecretName {
  text-overflow: ellipsis;
}

.inputSecretName:disabled {
  text-decoration: line-through;

  background-color: transparent;
}

.secret:not(:focus-within) .inputSecretName {
  border-color: transparent;
  background-color: transparent;
}

.secret:focus-within .inputSecretName {
  background-color: var(--ring-content-background-color);
}

.secret:focus-within .secretActions > * {
  visibility: hidden;
}
