@value unit: var(--ring-unit);

.row {
  font-size: var(--ring-font-size-smaller);

  &:hover {
    background-color: var(--ring-hover-background-color);
  }
}

.root {
  margin-bottom: calc(unit / 2);

  overflow-wrap: break-word;
}

.branch {
  margin-right: calc(unit / 2);
}

.canChange {
  position: relative;
}
