@value unit: var(--ring-unit);

.page {
  max-width: var(--tc-content-width);
}

.heading.heading {
  margin-bottom: calc(2 * unit);
}

.licensesText {
  margin: calc(2 * unit) 0;
}

.agents {
  composes: container from '../components/AgentsGroup/AgentsGroup.css';
}

.agent {
  margin: 0 calc(unit * -6) calc(unit / 2) calc(-6 * unit);
  grid-column: start / end;
}
