@value unit: var(--ring-unit);

.container {
  text-align: center;
}

.chart {
  height: calc(unit * 6 + 1px);
  margin-bottom: unit;
}

.prevNext {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.prevNextButton {
  height: auto;
  padding: 0 calc(unit / 2);

  vertical-align: -1px;

  line-height: 13px;
}

.prevNextButton[disabled] {
  opacity: 0;
}
