@value font, ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.agentLine {
  composes: font;

  display: flex;
  align-items: baseline;
}

.info {
  composes: ellipsis;

  flex-grow: 1;
}

.lastActive {
  flex-shrink: 0;

  margin-left: calc(unit * 2);
}

.connected {
  color: var(--ring-success-color);
}

.disconnected {
  color: var(--ring-error-color);
}
