@value unit: var(--ring-unit);

.label {
  display: inline-block;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-secondary-color);
}
