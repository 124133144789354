@value unit: var(--ring-unit);

.panel {
  margin-bottom: calc(unit * 4);
}

.heading,
.subheading {
  margin-right: unit;

  color: var(--ring-text-color);
}

.subheading {
  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
  line-height: calc(2 * unit);
}

.header.header {
  margin-bottom: 0;

  color: inherit;

  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
}

.headerToggle {
  padding: calc(unit / 2) calc(2.75 * unit);

  cursor: pointer;

  outline: none;
}

.headerToggle:hover {
  background: var(--ring-hover-background-color);
}

.headerToggle.disabled {
  cursor: default;
}

.content {
  margin-top: calc(0.5 * unit);
  margin-bottom: calc(unit * 2);

  padding-top: 0;
  padding-bottom: 0;

  &.border {
    padding: calc(unit * 2);
    padding-top: calc(unit * 1.5);

    border: 1px solid var(--ring-line-color);

    border-radius: 3px;
  }
}

.chevronIcon {
  position: relative;

  display: inline;

  height: auto;

  margin-right: calc(unit / 2);
  padding: 0;

  line-height: var(--ring-line-height-lower);
}

.chevronIconWrapper.chevronIconWrapper {
  color: var(--ring-icon-secondary-color);
}

.disabledIcon {
  visibility: hidden;
}
