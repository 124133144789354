@value unit: var(--ring-unit);

.agent {
  display: flex;

  align-items: baseline;

  padding: 0 calc(4 * unit);

  line-height: calc(4 * unit);
}

.agent:hover {
  background-color: var(--ring-hover-background-color);
}

.loading {
  cursor: progress;
}

.loadingToggle {
  cursor: wait;
}

.expandable {
  cursor: pointer;
}

.expandHandle {
  margin: 0 calc(0.5 * unit) 0 calc(-2.25 * unit);
}

.toggle {
  margin-right: unit;
}

.idle {
  color: var(--ring-secondary-color);
}

.disconnectionComment {
  white-space: nowrap;
}

.disconnected {
  color: var(--ring-error-color);
}

.idle,
.disconnected,
.disconnectionComment {
  font-size: var(--ring-font-size-smaller);
}

.runningPlaceholder {
  align-self: center;

  flex: 1 0;

  font-size: var(--ring-font-size-smaller);
}

.icon {
  margin-right: calc(unit / 2);
}

.link {
  font-size: var(--ring-font-size);
  line-height: calc(4 * unit);
}

.link,
.idle,
.split,
.disconnectionComment {
  flex: 0 0;
}

.link,
.idle,
.disconnectionComment,
.runningPlaceholder {
  margin-right: calc(2 * unit);
}

.statusComment {
  overflow: hidden;

  text-overflow: ellipsis;
}

.split {
  align-self: flex-start;

  height: calc(4 * unit);

  margin-left: auto;
}

.enableTimer,
.lastCommunicationTimer {
  white-space: nowrap;
}

.unassignButton {
  padding: 0;

  color: var(--ring-icon-color);
}

.loader {
  align-self: center;
  flex-shrink: 0;
}

.enableTimer,
.lastCommunicationTimer,
.authorize,
.unassignButton,
.loader {
  margin-left: unit;
}
