@value unit: var(--ring-unit);

.wrapper {
  position: relative;

  height: calc(unit * 4);

  border: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);

  font-size: var(--ring-font-size-smaller);
  line-height: calc(unit * 3);
}

.wrapperRounded {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.lineMargin {
  margin-top: 2px;
}

.line {
  position: absolute;
  top: -3px;
  left: -1px;

  width: calc(100% + 1px);
  height: 3px;

  background-color: var(--tc-success-progress-color);
}

.lineFull {
  width: calc(100% + 2px);
}

.progress {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;

  background-color: var(--tc-success-background-color);
}

.failedProgress {
  background-color: var(--tc-error-background-color);

  & .line {
    background-color: var(--ring-error-color);
  }
}

.left,
.right {
  position: absolute;
  top: calc(unit / 2);
}

.left {
  left: calc(unit * 2);
}

.right {
  right: calc(unit * 2);
}

.stop {
  margin-right: calc(0px - unit);
  margin-left: calc(unit / 2);
}

.overtimeIcon {
  margin-right: calc(unit / 2);

  color: inherit;
}
