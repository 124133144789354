@value unit: var(--ring-unit);

.placeholder {
  background-color: var(--ring-popup-background-color);
}

.content {
  display: flex;
  align-items: baseline;

  margin-left: calc(unit * 2);
}

.controls {
  flex-shrink: 0;
}

.more {
  margin-left: unit;

  color: var(--ring-secondary-color);

  font-size: 14px;
}

.more.more,
.switch {
  margin-right: unit;

  background: none;
}
