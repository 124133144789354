@value unit: var(--ring-unit);

.actions {
  display: flex;
  column-gap: calc(unit * 1.5);

  & > * {
    padding: 0;
  }
}

.settings {
  width: 100%;
}

.editSettingsForm {
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: calc(unit * 1.75);

  &.inline {
    flex-direction: row;
    column-gap: unit;

    & .actions {
      column-gap: calc(unit * 1.75);
    }
  }

  & .cancelButton {
    color: var(--ring-secondary-color);
  }
}

.deleteButton {
  margin-left: auto;
}
