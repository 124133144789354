@value resetButton from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.tableHeader {
  display: contents;

  line-height: var(--ring-line-height-lowest);

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.sortableColumnTitle {
  composes: resetButton;

  cursor: pointer;
}

.statusColumn {
  grid-column-start: status-start;
}

.durationColumn {
  grid-column-start: duration-start;

  text-align: right;
}

.buildColumn {
  grid-column-start: build-start;
}

.buildDetailsColumn {
  grid-column-start: build-details-start;
}

.changesColumn {
  grid-column-start: changes-start;
}

.agentColumn {
  grid-column-start: agent-start;
}

@media (max-width: 1400px) {
  .agentColumn {
    display: none;
  }
}

.buildStartDateColumn {
  grid-column-start: start-date-start;

  text-align: right;
}

.actionsColumn {
  grid-column-start: actions-start;
}

.statusColumn,
.durationColumn,
.buildColumn,
.changesColumn,
.agentColumn,
.buildStartDateColumn,
.actionsColumn {
  padding: unit 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  grid-row-start: var(--row);
}
