@value unit: var(--ring-unit);

.grid {
  --status-column: [start status-start] max-content;
  --problem-name-column: [problem-name-start] auto;
  --assigned-column: [assigned-start] minmax(auto, 240px);
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: start;

  margin-left: calc(unit * -2.25);
  grid-column-gap: calc(unit * 1.5);
  grid-template-columns: var(--status-column) var(--problem-name-column) var(--assigned-column) var(--actions-column) [end];
}

.list {
  display: contents;
}

.nothingFound {
  padding: unit 0;

  color: var(--ring-secondary-color);
}
