@value tipHoverBackgroundColor: #008eff;
@value unit: var(--ring-unit);

.tip {
  z-index: 1;

  display: flex;

  margin-right: unit;
  margin-bottom: unit;
}

.tipName {
  position: relative;

  padding: 5px 16px;

  cursor: pointer;

  color: var(--ring-content-background-color);
  border: none;
  border-radius: 3px;
  background: transparent;

  line-height: 1.1;

  &:hover {
    background-color: tipHoverBackgroundColor;
  }

  &.withLink {
    padding: 5px 12px 5px 16px;
  }
}

.readMore {
  display: inline-block;

  padding: 0 8px;

  color: var(--ring-content-background-color);
  border: none;
  background: none;

  &:hover {
    color: var(--ring-content-background-color);
    background-color: tipHoverBackgroundColor;
  }
}

.readMoreIcon {
  margin-top: 1px;

  transform: scale(0.8);
}
