@value chevronWidth: 24px;
@value unit: var(--ring-unit);

.chevronButton {
  padding: 0 5px;

  & .chevronIcon {
    transition: none;

    vertical-align: middle;

    color: var(--ring-icon-secondary-color);

    line-height: chevronWidth;
  }

  &:hover,
  &.hovered {
    & .chevronIcon {
      color: var(--ring-icon-hover-color);
    }
  }
}

.fakeChevronButton {
  width: chevronWidth;
  height: calc(2 * unit);
}
