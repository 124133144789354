@value unit: var(--ring-unit);

.result {
  margin-top: calc(unit * 2);
}

.code {
  outline: none;
}

.empty {
  color: var(--ring-secondary-color);
}

.error {
  color: var(--ring-error-color);
}
