@value unit: var(--ring-unit);

.hourglass {
  height: auto;

  white-space: nowrap;

  line-height: normal;

  &:not(:hover) {
    color: var(--ring-text-color);
  }
}

.statusIcon {
  margin: -2px 2px 0 -2px;

  line-height: normal;
}

.statusIcon.statusIcon svg {
  vertical-align: -4px;
}

.buildsPopup {
  max-height: 45vh;
}

.buildInList {
  max-width: calc(unit * 58);
  margin: 0;

  transition: background-color var(--ring-fast-ease);

  &:hover {
    transition: none;

    background-color: var(--ring-hover-background-color);
  }

  &:not(:first-child) {
    border-top: 1px solid var(--ring-line-color);
  }
}
