@value unit: var(--ring-unit);

.assignAgentsButton {
  margin-right: unit;
}

.emptyMessage {
  margin-top: calc(2 * unit);
}

.heading.heading {
  margin: calc(2 * unit) 0;
}

.cloudAgent {
  margin-bottom: calc(0.5 * unit);
}

.sortingSelect {
  display: block;

  margin-top: calc(2 * unit);
  margin-bottom: calc(2 * unit);
}

.warning {
  color: var(--ring-warning-color);
}
