@value unit: var(--ring-unit);

.additionalInfoLink {
  margin-right: calc(unit * 1.5);
}

.ignoredIcon > *,
.mutedIcon > * {
  color: var(--ring-secondary-color);
}

.passedIcon {
  color: var(--ring-icon-success-color);
}
