@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  align-items: center;

  min-height: calc(unit * 3);
}

.input {
  display: none;
}

.caption {
  display: inline-block;

  margin-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.wrapper.dragover {
  border-radius: var(--ring-border-radius-small);
  outline: dashed 1px var(--ring-main-color);
  outline-offset: calc(unit / 2);
}
