@value lineClamp, lineClampHasOverflow from '../../../ring-globals.css';

.showButton {
  padding: 0;

  color: var(--ring-secondary-color);
}

.content {
  composes: lineClamp;
}

.hasOverflow {
  composes: lineClampHasOverflow;
}
