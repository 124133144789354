@value unit: var(--ring-unit);

.pipelineSecretForm > div:first-child {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.pipelineSecretWrapForm {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  column-gap: unit;
}

.pipelineSecretFormInput {
  flex: 1;
}

.pipelineSecretFormHelperText {
  position: relative;

  margin-top: calc(unit / -2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
