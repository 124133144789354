@value unit: var(--ring-unit);

.dialog {
  width: 600px;
}

.dialogContent {
  overflow-x: hidden;

  padding-right: 0;
  padding-left: 0;
}

.build {
  display: block;

  border-radius: 0;

  outline: 0;

  box-shadow: 0 1px var(--ring-line-color);

  &:hover {
    text-decoration: none;

    background-color: var(--ring-hover-background-color);
  }

  &:focus{
    background-color: var(--ring-selected-background-color);
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

.buildContent {
  padding-right: calc(unit * 4);
  padding-left: calc(unit * 4);
}

.firstRow {
  display: flex;

  font-size: var(--ring-font-size-smaller);
}

.numberWithBranch {
  flex-grow: 1;
}

.label {
  text-align: right;

  color: var(--ring-text-color);
}

.number {
  color: var(--ring-link-color);
}

.branch {
  margin-left: unit;
}

.loader,
.emptyMessage {
  margin-left: calc(unit * 4);
}

.status {
  line-height: var(--ring-line-height-lower);
}

.statusIcon {
  margin-right: calc(unit / 2);
}
