@value unit: var(--ring-unit);
@value font from '../../../../../../ring-globals.css';

.pagination {
  flex-direction: column;

  margin-top: auto;
  padding-top: calc(unit * 4);
}

.pages {
  composes: font;

  display: block;

  margin-bottom: calc(unit / 2);

  color: var(--ring-content-background-color);
}

.controlButton {
  z-index: 1;

  margin-right: unit;

  color: var(--ring-text-color);
  background: var(--ring-content-background-color);
  box-shadow: var(--ring-content-background-color);
}
