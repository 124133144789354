.iconsWrapper {
  display: inline-block;
}

.icon {
  height: 16px;
}

.parallelismIcon {
  margin-left: calc(var(--ring-unit) * -1);

  color: var(--ring-icon-color);
}

.parallelismIconSuccess {
  color: var(--ring-success-color);
}

.parallelismIconError {
  color: var(--ring-icon-error-color);
}
