@value unit: var(--ring-unit);

.integration {
  position: relative;

  display: flex;
  align-items: baseline;
  column-gap: unit;

  margin-bottom: calc(unit * 1.5);

  &:hover .actions > * {
    opacity: 1;
  }
}

.toggle {
  display: flex;

  & span:first-child {
    flex-shrink: 0;

    margin-top: 3px;
  }
}

.note {
  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.disabled .name,
.disabled .note {
  color: var(--ring-secondary-color);
}

.deleted .name,
.deleted .note {
  text-decoration: line-through;
}
