@value unit: var(--ring-unit);

.loader {
  display: block;

  width: 16px;

  margin: 0 calc(4 * unit);
}

.searchInput,
.tree,
.panel {
  line-height: calc(3 * unit);
}

.searchInput {
  margin: 0 calc(4 * unit) unit;
}

.tree {
  overflow: auto;
  flex: 1 1;
}

.emptyList {
  margin: 0 calc(4 * unit);
}

.expandableGroup {
  margin: 0 0 calc(4 * unit) 0;
}

.expandableGroupHeader {
  overflow: hidden;

  padding: 0 calc(4 * unit);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.poolItem {
  overflow: hidden;

  padding-right: calc(4 * unit);

  padding-left: calc(8 * unit);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkboxContainer {
  display: inline;
}

.itemCheckboxContainer {
  display: flex;
  align-items: center;

  height: calc(3 * unit);

  line-height: calc(3 * unit);
}

.checkboxCell {
  top: 0;

  flex-shrink: 0;
}

.checkboxLabel {
  position: relative;
  z-index: 10;

  overflow: hidden;

  flex-grow: 1;

  cursor: pointer;

  user-select: none;

  text-overflow: ellipsis;
}

.poolItem:hover {
  background: var(--ring-hover-background-color);
}

.summary {
  margin-bottom: unit;
}

.itemTitle {
  display: flex;
}

.itemName {
  overflow: hidden;

  flex: 1 1;

  text-overflow: ellipsis;
}

.itemLabel {
  flex: 0 0;

  color: var(--ring-secondary-color);
}

.panel.panel {
  margin-top: 0;
  padding-left: calc(4 * unit);

  border-top: 1px solid var(--ring-popup-border-color);
}
