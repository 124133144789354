@value unit: var(--ring-unit);

.grid {
  position: relative;

  overflow: hidden;

  margin-left: calc(unit * -4);
  padding-left: calc(unit * 4);
}

.underlined {
  border-bottom: 1px solid var(--ring-line-color);
}
