@value unit: var(--ring-unit);

.container {
  display: block;

  margin-top: calc(unit * -1);

  padding-bottom: calc(unit * 1.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}
