@value ellipsis, font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  display: grid;

  overflow: hidden;

  margin: calc(unit * -2) calc(unit * -4) calc(unit * 4) calc(unit * -4);
  padding: calc(unit * 2.5) calc(unit * 4) 0 calc(unit * 4);
  padding-bottom: calc(unit * 2.5);

  border-bottom: 1px solid var(--ring-line-color);
  grid-gap: calc(unit * 2);
  grid-template-columns: auto max-content;
  grid-template-rows: auto auto;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'heading actions';
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;

  overflow: hidden;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.title {
  composes: ellipsis;
}

.title.title {
  margin-bottom: unit;
}

.branchSelectButton {
  composes: font-smaller;

  display: inline-block;

  height: calc(unit * 3);
  margin-top: calc(unit / 2);

  & > button[type='button'] {
    padding: 0;
  }

  & > button {
    background-color: transparent;

    &:hover {
      color: var(--ring-link-hover-color);
      background-color: transparent;
    }

    & svg {
      margin-right: calc(unit / 2);
    }
  }
}

.button {
  margin-left: unit;
}
