@value unit: var(--ring-unit);

.info {
  display: grid;
  grid-template-columns: calc(unit * 18) fit-content(calc(unit * 100));

  row-gap: calc(unit * 1.5);

  margin-bottom: calc(unit * 1.5);
}

.caption {
  margin-top: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
