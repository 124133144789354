@value unit: var(--ring-unit);

.firstBuildWithSameChanges {
  margin-bottom: calc(unit * 1.5);
  padding-bottom: unit;

  border-bottom: 1px solid var(--ring-line-color);
}

.changesTypeFilter {
  margin-bottom: calc(unit * 1.5);
}

.firstBuildWithSameChanges + .changesTypeFilter {
  margin-top: calc(unit / 2);
}

.changesButtons {
  display: flex;
  align-items: center;

  & > * {
    margin-left: unit;
  }

  & > *:first-child {
    margin-left: 0;
  }
}

.changesButtons:not(:empty) {
  margin-top: unit;
}

.count {
  margin-left: unit;

  color: var(--ring-secondary-color);
}
