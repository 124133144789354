@import '../../common/Avatar/Avatar.css';

@value compensated from '@jetbrains/ring-ui/components/header/header.css';
@value resetButton from '../../../ring-globals.css';
@value unit: var(--ring-unit);
@value headerHeight: calc(unit * 8);

.container {
  --ring-text-color: #bbb;
  --ring-main-color: #72bbff;

  display: flex;

  width: 100%;
  height: headerHeight;
}

.text {
  color: var(--ring-text-color);
}

.icon {
  color: var(--ring-icon-color);
}

.headerModule1 {
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  margin-right: calc(unit / 2);
  justify-items: flex-end;
}

.tray {
  flex: 1 !important;
  justify-content: flex-end;

  margin: 0 !important;
  justify-items: flex-end;
}

.trayItem {
  height: compensated;
}

.login {
  margin-left: calc(unit * 3);

  &:hover {
    text-decoration: none;

    color: var(--ring-content-background-color);
  }
}

.guest {
  background-color: #008eff;
}

.rightWrapper {
  display: flex;
}

.headerPlaceholder {
  height: calc(unit * 8);

  background-color: var(--tc-message-background-color);
}

.avatarWrapper {
  composes: resetButton;

  cursor: pointer;
}

.avatar {
  margin: 0;
}

.avatarInner {
  overflow: visible;

  vertical-align: calc(unit * -1.5);
}
