@value unit: var(--ring-unit);

.row {
  display: contents;

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.row:hover {
  &::before {
    background-color: var(--ring-hover-background-color);
  }
}

.statusColumn,
.problemNameColumn,
.assignedColumn,
.actionsColumn {
  z-index: 2;

  padding: unit 0;
}

.statusColumn {
  display: flex;

  align-items: center;

  grid-column-start: status-start;
  grid-row-start: var(--row);
}

.problemNameColumn {
  overflow: hidden;
  align-items: baseline;
  grid-column-start: problem-name-start;
  grid-row-start: var(--row);
}

.assignedColumn {
  grid-column-start: assigned-start;
  grid-row-start: var(--row);
}

.actionsColumn {
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.checkbox {
  margin-left: 0;
}

.name {
  display: flex;
  gap: calc(unit * 0.75);

  align-items: baseline;

  font-weight: 600;
}

.tableRow::before {
  left: calc(unit * -1.75);
}
