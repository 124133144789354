@value unit: var(--ring-unit);

.header.header {
  margin-bottom: calc(unit * 4);
}

.loader {
  margin-right: unit;
  margin-bottom: 3px;
}
