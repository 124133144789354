@value unit: var(--ring-unit);

.button {
  composes: job from '../JobTile/JobTile.css';

  margin: 0;

  cursor: pointer;
  text-align: left;

  border: 1px dashed var(--ring-icon-color);

  font-family: var(--ring-font-family);
  font-weight: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:hover {
    border: 2px dashed var(--ring-action-link-color);
  }

  &[disabled] {
    pointer-events: none;

    opacity: 0.7;

    color: var(--ring-secondary-color);
  }
}

.icon {
  color: var(--ring-icon-color);
}

.name {
  composes: name from '../JobTile/JobTile.css';
}

.button:hover .name {
  background-color: transparent;
}
