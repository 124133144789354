@value ellipsis, font-smaller from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.info {
  box-sizing: border-box;
  width: calc(unit * 58);
  margin: 0 calc(unit * -1);
  padding: unit calc(unit * 2);

  transition: background-color var(--ring-fast-ease);

  &:hover {
    transition: none;

    background-color: var(--ring-hover-background-color);
  }
}

.path.path {
  padding-bottom: unit;

  border-bottom: 1px solid var(--ring-line-color);

  font-size: inherit;
  line-height: inherit;
}

.row {
  display: flex;
  align-items: baseline;

  &:not(:first-child) {
    margin-top: calc(unit / 2);
  }

  & > * {
    flex-shrink: 0;

    &:not(:first-child) {
      margin-left: unit;
    }
  }
}

.header {
  composes: row;

  margin-bottom: unit;
}

.position {
  margin-right: calc(unit / 2);
}

.link,
.triggered {
  composes: ellipsis;

  flex: 1 0 0;

  min-width: 0;
}

.actions {
  margin: -3px calc(unit * -1);
}

.changes {
  composes: font-smaller;
}
