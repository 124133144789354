.stateButton.active .icon,
.stateButton:hover .icon {
  color: var(--ring-text-color);
}

.stateIcon {
  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}
