@value unit: var(--ring-unit);

.section {
  position: relative;

  padding: calc(unit * 2) calc(unit * 4);
  padding-left: calc(unit * 2.5);

  border-bottom: calc(unit / 2) solid var(--ring-sidebar-background-color);

  &:last-child {
    margin-bottom: 0;
  }
}

.collapsibleRegion {
  position: relative;

  margin-top: calc(unit * 1.5);

  & *:last-child {
    margin-bottom: 0;
  }
}
