@value unit: var(--ring-unit);

.wrapper {
  margin-bottom: unit;

  line-height: 24px;
}

.select {
  margin-bottom: unit;

  & > button {
    color: var(--ring-text-color);
  }
}

.selectPopup {
  min-width: 640px;
}
