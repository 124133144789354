@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.wrapper {
  composes: font-smaller-lower;
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-secondary-color);

  &.myBuild {
    color: var(--ring-main-color);
  }
}
