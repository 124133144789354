@value unit: var(--ring-unit);

.row {
  flex-flow: row nowrap;

  margin: 0 calc(unit * -4) unit;

  padding: 1px calc(unit * 4) 3px calc(unit * 4);

  &.changed {
    background-color: var(--ring-sidebar-background-color);
    box-shadow: inset 3px 0 var(--ring-main-color);
  }

  &:hover,
  &.changed:hover {
    background-color: var(--ring-hover-background-color);
  }

  & > div {
    margin: 0;
  }
}

.nameCol {
  flex: 1;

  min-width: 0;

  padding-right: unit;

  overflow-wrap: break-word;
}

.statusCol {
  width: calc(8 * unit);
  padding-right: unit;
  padding-left: unit;

  font-size: var(--ring-font-size-smaller);
}

.durationCol {
  width: calc(unit * 15);
  padding-right: 0;

  text-align: right;

  font-size: var(--ring-font-size-smaller);
}

.durationDiffCol {
  width: calc(unit * 16);
  padding-left: calc(unit / 2);

  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
}

.suite {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
