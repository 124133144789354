@value unit: var(--ring-unit);

.filter {
  display: flex;
  align-items: center;
}

.input {
  width: calc(30 * unit);
  margin: 0;
}

.hideButton {
  margin-left: calc(2 * unit);
}
