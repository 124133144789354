@value font, font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  display: grid;

  overflow: hidden;

  margin: calc(unit * -2) calc(unit * -4) calc(unit * 4) calc(unit * -4);
  padding: calc(unit * 2.5) calc(unit * 4) 0 calc(unit * 4);
  padding-bottom: calc(unit * 2.5);

  border-bottom: 1px solid var(--ring-line-color);
  grid-gap: calc(unit * 2);
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'breadcrumbs breadcrumbs breadcrumbs'
    'heading pipelineNavigation actions'
    'heading pipelineNavigation runInformation';
}

.breadcrumbs {
  grid-area: breadcrumbs;
}

.heading {
  grid-area: heading;

  overflow: hidden;
}

.pipelineNavigaion {
  grid-area: pipelineNavigation;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.runInformation {
  composes: font-smaller;

  display: flex;
  align-items: end;
  flex-direction: column;
  grid-area: runInformation;
}

.historyLink {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.historyChevron {
  margin-left: -3px;
}

.titleWrapper {
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}

.iconStatus {
  float: left;

  height: 20px;
  margin-top: calc(unit / 2);
  margin-right: calc(unit / 2);

  & span,
  & span svg {
    width: 20px;
    height: 20px;
  }
}

.historyChevron.historyChevron > svg {
  vertical-align: -4px;
}

.branchAndStatus {
  composes: font-smaller;

  margin-top: calc(unit / 2);
}

.branch {
  margin-left: calc(unit / 1.5);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.success.success {
  color: var(--ring-success-color);
}

.failure.failure {
  color: var(--ring-error-color);
}

.queued.queued {
  color: var(--ring-secondary-color);
}

.canceled.canceled {
  color: var(--ring-secondary-color);
}

.triggered {
  composes: font-smaller;

  display: flex;
  align-items: baseline;

  margin-top: calc(unit / 2);
}

.button {
  margin-left: unit;
}

.stop {
  margin-right: calc(unit * -1);
}

.optimized {
  color: var(--ring-text-color);
}

.optimizedIcon {
  margin-right: calc(unit / 2);
}

.reusedPopup {
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.reusedItem {
  margin-top: 14px;

  line-height: var(--ring-line-height-lower);
}

.icon {
  margin-right: calc(unit / 2);
}

.reusedItemInfo {
  margin-top: 6px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.cancel {
  vertical-align: 2px;
}
