@value unit: var(--ring-unit);

.grid {
  padding-right: 0;
  padding-left: 0;
}

.controls {
  display: flex;
  align-items: flex-start;

  margin-top: 0;
}

.filter.filter {
  display: inline-block;
  align-self: flex-start;

  margin-left: calc(unit * 2);
}

.empty {
  padding-top: unit;
}

.emptyIcon {
  color: var(--ring-main-color);
}

.limitationMessage {
  margin-top: calc(unit * 3);

  color: var(--ring-secondary-color);
}
