@value ellipsis from '../../../../../ring-globals.css';
@value leftShadow: -5px 0 4px 0 var(--ring-content-background-color);
@value rightShadow: 5px 0 4px 0 var(--ring-content-background-color);
@value topShadow: 0 0 0 1px var(--ring-content-background-color);
@value unit: var(--ring-unit);

.stage {
  position: relative;

  min-width: unit;
}

.stage,
.popup {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.separator,
.stageLine {
  position: absolute;
  z-index: 1;
  left: 0;

  content: '';

  background-color: var(--ring-borders-color);
}

.separator {
  bottom: 0;

  width: 2px;
  height: 6px;
}

.separatorFinish {
  right: 0;
  left: auto;
}

.stageLine {
  bottom: -1px;

  width: 100%;
  height: 3px;
}

.successfulLine {
  background-color: var(--tc-success-progress-color);
}

.failedLine {
  background-color: var(--ring-error-color);
}

.canceledLine {
  background-color: var(--ring-warning-color);
}

.shortLabel {
  composes: ellipsis;

  display: inline-block;

  width: calc(100% - unit);
  min-width: unit;

  height: 24px;

  color: var(--ring-text-color);

  line-height: 16px;
}

.fullLabel {
  white-space: nowrap;

  color: var(--ring-text-color);
}

.successfulLabel.successfulLabel {
  color: var(--ring-success-color);
}

.failedLabel.failedLabel {
  color: var(--ring-error-color);
}

.canceledLabel.canceledLabel {
  color: var(--ring-warning-color);
}

.popup {
  padding: 0 2px;

  pointer-events: none;

  border: none;

  background-color: var(--ring-content-background-color);

  box-shadow: leftShadow, rightShadow, topShadow;
}

.popupHidden {
  display: none;
}

.duration {
  color: var(--ring-secondary-color);
}

.anchor {
  height: 100%;
}

.action {
  display: block;

  height: 100%;

  &:hover {
    & .stageLine {
      height: calc(unit - 1px);
    }
  }
}

.viewPoint {
  position: absolute;

  z-index: 2;
  bottom: 11px;

  display: block;

  width: 14px;
  height: 14px;
  margin-left: -6px;

  transform: rotate(45deg);

  border-radius: 100% 100% 0;
  background-color: var(--ring-main-color);

  &::before {
    position: absolute;

    top: 4px;
    left: 4px;

    width: 6px;
    height: 6px;

    content: '';

    border-radius: 100%;

    background-color: var(--ring-content-background-color);
  }
}
