@value unit: var(--ring-unit);

.popup {
  padding: 0 calc(unit * 3) calc(unit * 2);
}

.templateList {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-bottom: 0;
}

.description {
  margin-bottom: calc(unit / 2);
}

.templateEntities {
  width: calc(unit * 20);

  margin-left: calc(unit / 2);
}

.title {
  flex-shrink: 0;
}
