@value unit: var(--ring-unit);

.toggleContainer {
  display: block;

  margin-bottom: unit;
}

.noBuildTypes {
  box-sizing: border-box;
  margin-top: unit;

  padding: unit calc(unit * 2);

  text-align: left;

  color: var(--ring-secondary-color);

  border: 1px solid var(--ring-line-color);
  border-radius: 3px;
  background: var(--ring-content-background-color);

  font-size: var(--ring-font-size-smaller);
}

.createLink {
  padding-left: unit;

  color: var(--ring-main-color);
}
