@value unit: var(--ring-unit);

.editButton {
  height: auto;
  margin-left: auto;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height-lowest);

  &:focus-visible {
    opacity: 1;
  }
}
