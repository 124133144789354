.stopBuild {
  white-space: nowrap;
}

.icon {
  height: auto;

  line-height: inherit;
}

.stopIcon {
  vertical-align: -1px;
}

.invisible {
  display: none;
}
