@value font-smaller from '../../../../ring-globals.css';
@value holderWidth, holderPadding from '../CleanupProjectPage.css';
@value whatToSaveColWidth, artifactsColWidth from '../Listings/Rules/RulesList/RuleItem/RuleItem.css';
@value unit: var(--ring-unit);

.headerContainer {
  position: sticky;
  z-index: var(--ring-fixed-z-index);
  top: 0;

  margin: calc(unit * -1) calc(unit * -4 + 1px) 0;
}

.innerContainer {
  padding: unit calc(unit * 4 - 1px) 0;

  background: var(--ring-content-background-color);
}

.stuck {
  box-shadow: 0 1px var(--ring-popup-border-color);

  &::before {
    position: absolute;
    z-index: -1;

    top: 0;
    right: calc(unit * 2);
    bottom: 0;
    left: calc(unit * 2);

    content: '';

    box-shadow: 0 2px calc(unit * 2) var(--ring-popup-shadow-color);
  }
}

.projectName {
  padding: 0;

  font-size: calc(unit * 2);
  font-weight: bold;
}

.projectDescription {
  composes: description from '../CleanupProjectPage.css';

  margin-bottom: 0;
}

.row {
  composes: font-smaller;

  height: calc(unit * 3 - 2px);

  margin-left: calc(unit * 32);
  padding-top: 0;
  padding-bottom: 0;

  color: var(--ring-secondary-color);

  &:hover {
    cursor: default;

    background: none;
  }
}

.whatIcon {
  composes: helpIcon from '../CleanupProjectPage.css';
}

.infoPopup {
  padding: unit calc(unit * 2) calc(unit * 2);
}

.modifiedIcon {
  fill: var(--ring-icon-color);

  padding-left: 2px;
}

.invisible {
  visibility: hidden;
}
