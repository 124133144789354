@value unit: var(--ring-unit);

.wrapper {
  padding: calc(unit * 2) 0;
}

.changeInfo {
  margin: unit calc(unit * -4) 0;
  padding: calc(unit * 2) calc(unit * 4) 0;

  box-shadow: 0 -1px var(--ring-line-color);
}

.smallPadding {
  & .changeInfo {
    margin: unit calc(unit * -2) 0;
    padding: calc(unit * 2) calc(unit * 2) 0;
  }
}

.showFiles {
  &.wrapper {
    padding-top: 0;

    padding-bottom: calc(unit * 2);
  }
}
