@import '@jetbrains/ring-ui/components/global/variables.css';

@value error as error_ from '../../TwoFactorAuthPages.css';
@value unit: var(--ring-unit);

/* pure brandbook colors */
@value tc-purple: #9135e0;
@value tc-blue: #05c1fd;
@value tc-green: #18d68c;

.qr {
  display: block;

  background: linear-gradient(90deg, tc-purple, tc-blue, tc-green);
}

.qrWrapper {
  margin-top: unit;
  padding: unit;

  border: 1px solid var(--ring-line-color);
}

.keyRepresentations {
  display: flex;

  margin-top: calc(unit * 1.5);

  & > div {
    margin-right: calc(unit * 4);
  }
}

.password.password /* to override ring ui */ {
  width: calc(264px + 2 * unit);
  margin-bottom: calc(unit * 2);
}

.passwordInner {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
}

.error {
  composes: error_;
}
