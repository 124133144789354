.row:hover {
  background-color: var(--ring-hover-background-color);
}

.wrapper {
  position: relative;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-larger);
}

.changed {
  color: var(--ring-link-hover-color);
}
