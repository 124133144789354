@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  composes: font-smaller-lower;

  white-space: pre-wrap;

  color: var(--ring-secondary-color);
}

.icon {
  margin-right: calc(unit / 2);
}
