@value unit: var(--ring-unit);

.buildTypeOverviewFooter {
  min-height: var(--ring-line-height);

  margin-top: calc(unit * 2);

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}

.warning {
  margin-top: 0;
}
