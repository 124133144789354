@value unit: var(--ring-unit);

.tabs {
  width: fit-content;
  margin-bottom: calc(unit * 2);
}

.radioItem {
  padding: 2px 0;
}

.errorDescription {
  width: calc(unit * 62.5);
  margin-top: unit;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}
