@value unit: var(--ring-unit);

.actions {
  display: flex;
  gap: calc(unit * 2);

  padding: calc(unit * 2) calc(unit * 2) 0 0;
}

.secondary {
  padding: 0;
}
