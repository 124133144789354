@value font from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.buildInfo {
  position: relative;

  overflow: hidden;

  flex-grow: 1;

  max-width: calc(unit * 50);
}

.path.path {
  margin: 0 calc(unit * 2);
  padding: unit 0;

  border-bottom: 1px solid var(--ring-line-color);

  font-size: inherit;
  line-height: inherit;
}

.infoWrapper {
  composes: font;

  position: relative;

  box-sizing: border-box;

  min-height: calc(unit * 16);

  padding: unit calc(unit * 2) calc(unit * 1.5);

  font-size: var(--ring-font-size-smaller);
}

.progress {
  position: absolute;

  top: 1px;
  bottom: 0;
  left: 0;

  background-color: var(--tc-success-background-color);
}

.failing {
  background-color: var(--tc-error-background-color);
  box-shadow:
    0 -1px var(--ring-icon-error-color),
    inset 0 1px var(--ring-icon-error-color);
}

.head {
  display: flex;
  align-items: baseline;

  height: calc(unit * 2.5);
  margin-right: calc(unit * -1);
}

.duration {
  flex: 1 0 auto;

  padding-right: calc(unit * 2);

  white-space: nowrap;
}

.finishedCompact {
  margin-left: unit;

  color: var(--ring-secondary-color);
}

.changes {
  font-size: var(--ring-font-size-smaller);
}

.changes,
.changesWrapper,
.number {
  min-width: 0;
}

.status {
  display: block;
  overflow-wrap: break-word;

  margin: calc(unit / 2) 0;

  font-size: var(--ring-font-size);
}

.container {
  & .infoWrapper {
    height: calc(unit * 8);
    min-height: auto;
  }

  & .status {
    overflow: hidden;

    margin-bottom: 0;

    white-space: nowrap;

    text-overflow: ellipsis;
  }

  & .details {
    display: none;
  }

  &.hover,
  &:focus-within {
    & .infoWrapper {
      height: auto;
      min-height: calc(unit * 16);
    }

    & .status {
      overflow: visible;

      margin-bottom: calc(unit / 2);

      white-space: initial;

      text-overflow: initial;
    }

    & .details {
      display: block;
    }
  }
}

.successStatus {
  color: var(--ring-success-color);
}

.failureStatus {
  color: var(--ring-error-color);
}

.footer {
  display: flex;
  align-items: baseline;
}

.icon {
  display: inline;

  margin-right: calc(unit / 2);

  white-space: nowrap;

  &::after {
    content: '\2060';
  }
}

.timeInQueue {
  display: inline-block;
}

.started {
  display: inline-block;

  margin-left: unit;

  white-space: nowrap;
}

.number {
  min-width: calc(unit * 7);

  padding-right: 26px;
}

.branch {
  min-width: calc(unit * 8);
  margin-left: auto;
}

.queueCircle {
  display: inline-block;

  width: 14px;

  height: 14px;

  margin-right: calc(unit / 2);

  vertical-align: -2px;

  border-radius: 50%;
  background-color: var(--ring-borders-color);
}

.overtimeIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}
