.content {
  &:hover {
    background-color: var(--ring-hover-background-color);
  }
}

.defaultValue {
  display: flex;
  flex-direction: column;

  margin-top: calc(var(--ring-unit) / 2);

  color: var(--ring-secondary-color);
}

.addedParameter {
  background-color: var(--tc-success-background-color);
  box-shadow: inset 3px 0 var(--tc-success-progress-color);
}

.modifiedParameter {
  background-color: var(--ring-sidebar-background-color);
  box-shadow: inset 3px 0 var(--ring-main-color);
}

.highlighting {
  color: var(--ring-link-hover-color);
}
