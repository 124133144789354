.iframe {
  width: 1px;
  min-width: 100%;

  border: none;
}

.hidden {
  visibility: hidden;
}
