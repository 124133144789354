@value unit: var(--ring-unit);

.wrapper {
  display: inline-block;

  font-size: var(--ring-font-size-smaller);
}

.title {
  font-size: inherit;
}

.inlineTitle {
  font-weight: bold;
}

.reasonDuration {
  font-weight: bold;
}

.popup {
  padding: unit calc(unit * 2) calc(unit * 2);
}

.popup .reason:not(:first-child) {
  margin-top: 5px;
}

.inlineReasons {
  margin-bottom: calc(unit / 2);

  font-size: inherit;
}

.dropdown {
  margin-bottom: -1px;
}

.anchor {
  height: auto;

  color: inherit;
}

.iconsOffset {
  display: inline-block;

  width: calc(unit * 2);
}

.queuedIcon {
  margin-left: -1px;

  color: var(--ring-icon-color);
}

.ownQueueTimeIcon {
  composes: queue from '../../pages/BuildPage/DependenciesTab/DependenciesTimeline/Legend/Legend.css';
}
