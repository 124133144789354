@value ellipsis from '../../../../ring-globals.css';
@value unit var(--ring-unit);

.grid {
  grid-template-columns: 138px auto;
  grid-column-gap: unit;
  grid-row-gap: calc(unit * 1.5);
}

.names {
  overflow: auto;

  max-height: 120px;
}

.nameContainer {
  display: flex;
  gap: unit;

  &:not(:last-child) {
    padding-bottom: unit;
  }
}

.name {
  overflow: hidden;
}

.primary {
  composes: ellipsis;

  padding-bottom: calc(unit / 4);

  font-weight: 600;
}

.details {
  composes: ellipsis;

  color: var(--ring-secondary-color);
}

.separator {
  border-bottom: 1px solid var(--ring-line-color);
  grid-column: span 2;
}

.projectScope {
  overflow: hidden;

  padding-right: calc(unit / 2);
}

.scopeDetails {
  padding-top: calc(unit / 2);

  color: var(--ring-secondary-color);
}

.relatedProjectsContainer {
  margin-top: calc(unit * 1.5);
}

.relatedProjects {
  margin: 0;
  padding-left: calc(unit * 2);

  list-style-type: '\2022';

  color: var(--ring-link-color);
}

.relatedProjects li {
  padding-left: unit;
}

.investigationHint {
  padding: calc(unit * 1.5);

  border-radius: 8px;
  background-color: var(--ring-sidebar-background-color);
  grid-column: span 2;
}

.unmuted {
  display: flex;
  flex-direction: column;
  gap: unit;
}

.date {
  margin-right: calc(unit * -1);
}
