.row {
  margin-bottom: var(--ring-unit);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowHeading {
  font-weight: bold;
}
