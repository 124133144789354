@value unit: var(--ring-unit);

.port {
  display: flex;
  align-items: center;

  margin-top: calc(unit / 2);
  column-gap: calc(unit / 2);
}

.port input::-webkit-outer-spin-button,
.port input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.port input {
  width: calc(unit * 16);

  -moz-appearance: textfield;
}

.error {
  min-height: var(--ring-line-height-lowest);
  margin-top: calc(unit / 4);

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
