.flash.flash,
.flash .queuedIcon {
  transition: none;

  color: var(--ring-link-hover-color);
}

.flash.flash.extraHighlight {
  background-color: var(--ring-selected-background-color);
}
