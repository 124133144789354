@value unit: var(--ring-unit);

.actions {
  display: flex;
  align-items: center;

  margin-right: calc(unit * -1);

  margin-left: auto;

  & > * {
    transition: opacity var(--ring-ease);
  }
}

.hiddenDefault > * {
  opacity: 0;

  &:focus-visible {
    opacity: 1;
  }
}

.addButton {
  opacity: 1;
}
