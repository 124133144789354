@value unit: var(--ring-unit);

.info {
  padding: unit 0;
}

.infoIcon {
  margin-right: unit;
  margin-left: 1px;
}
