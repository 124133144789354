@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-secondary-color);

  line-height: normal;
}

.failed {
  color: var(--ring-icon-error-color);
}

.successful {
  color: var(--ring-icon-success-color);
}

.arrow {
  display: none;
}

.arrowWrapper:hover .icon {
  display: none;
}

.arrowWrapper:hover .arrow {
  display: inline;
}
