@value unit: var(--ring-unit);

.container {
  display: flex;

  padding-top: unit;
  gap: unit;
}

.message {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  flex-shrink: 0;

  width: calc(unit * 4.5);
  padding-left: 1px;
}

.mainAction {
  margin-right: unit;
  padding: 0;
}

.secondaryAction {
  padding: 0;

  color: #737577;
}
