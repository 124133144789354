.row {
  grid-column: start / end;
}

.title.title {
  font-size: inherit;
  line-height: inherit;
}

.container {
  display: contents;
}
