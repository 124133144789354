@value unit: var(--ring-unit);

.container {
  max-width: calc(unit * 47.5);
  padding: calc(unit * 1.5) calc(unit * 3);
}

.caption {
  margin: 0;
}

.caption.caption {
  margin-right: 0;
}

.closeButton {
  position: absolute;
}
