@value unit: var(--ring-unit);

.nodes {
  padding-left: 0;

  list-style-type: none;
}

.tree > .nodes {
  margin: 0;
  padding: 0;
}

.checkAll {
  display: flex;
  align-items: baseline;

  padding: calc(unit / 2) 0;
}

.checkAllCheckbox {
  line-height: var(--ring-line-height-lowest);
}

.checkAllCount {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
