@value unit: var(--ring-unit);

.dockerfileSource > div {
  height: 100%;
}

.dockerfileSource,
.dockerfilePath {
  width: 100%;

  margin-bottom: calc(unit * 1.5);
}

.dockerfileSource,
.dockerfileSourceInput {
  min-height: 104px !important;
  max-height: 164px !important;
}

.radio {
  margin-bottom: calc(unit / 2 * 1.5);
  padding-top: 0;
}
