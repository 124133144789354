@value error as error_ from '../TwoFactorAuthPages.css';
@value unit: var(--ring-unit);

.container {
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: center;

  height: 100vh;
}

.logo {
  width: 100px;
  height: 100px;

  & svg {
    width: 100px;
    height: 100px;
  }
}

.form {
  width: 240px;
}

.heading.heading {
  font-size: 30px;
  font-weight: 200;
}

.password,
.wideButton {
  width: 100%;
}

.password {
  margin-bottom: calc(unit * 2);
}

.error {
  composes: error_;
}

.errorPlaceholder {
  height: 60px;
  padding-top: 8px;
}
