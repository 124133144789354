@value unit: var(--ring-unit);

.input {
  margin-bottom: calc(unit * 4);
}

.error {
  margin-top: calc(unit * 2);

  color: var(--ring-error-color);
}
