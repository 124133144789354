@value unit: var(--ring-unit);

.filter {
  display: flex;
  flex-direction: column;

  margin-bottom: calc(unit * 2);
}

.firstFiltersLine,
.seccondFiltersLine {
  display: flex;
  align-items: baseline;

  flex-direction: row;

  flex-wrap: wrap;
}

.stateButton.active .ignoredIcon,
.stateButton:hover .ignoredIcon,
.stateButton.active .mutedIcon,
.stateButton:hover .mutedIcon {
  color: var(--ring-text-color);
}

.stateButton.active .failedIcon,
.stateButton:hover .failedIcon {
  color: var(--ring-icon-error-color);
}

.stateButton.active .passedIcon,
.stateButton:hover .passedIcon {
  color: var(--ring-icon-success-color);
}

.stateIcon {
  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}

.filterLabel {
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit / 2);
}

.count {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.viewSelect,
.statusSelect {
  margin-right: unit;
}

.searchButton,
.filterOnlyNew,
.filterWithoutInvestigator {
  margin-right: calc(unit * 2);
}

.viewSelect,
.statusSelect,
.searchButton,
.filterOnlyNew,
.filterWithoutInvestigator {
  margin-bottom: calc(unit * 2);

  &:last-child {
    margin-right: 0;
  }
}

.checkboxSection {
  display: flex;
  flex-wrap: nowrap;
}

.scopeFilter {
  margin-bottom: calc(unit * 2);
}
