@value unit: var(--ring-unit);

.timestamp.timestamp {
  padding: unit calc(unit * 2) 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.separator {
  min-height: 0;
  padding-bottom: 0;
}

.popup {
  min-width: calc(unit * 29);
}

.hotkey {
  display: inline-block;

  margin-left: unit;

  color: var(--ring-disabled-color);
}
