@value unit: var(--ring-unit);

.content {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.log {
  flex-grow: 1;
}

.dialog {
  width: calc(100vw - unit * 4 * 2);
  height: calc(100vh - unit * 2.5 * 2);
}

.overlay {
  padding: 0;
}
