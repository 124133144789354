@value unit: var(--ring-unit);

.rotatedBar {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  height: calc(unit * 4);

  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;
}

.label {
  display: inline-block;

  height: calc(unit * 4 - 1px);
  padding: 0 16px;

  cursor: pointer;

  white-space: nowrap;

  color: var(--ring-text-color);
  background-color: var(--ring-sidebar-background-color);

  line-height: calc(unit * 4 - 1px);

  &:hover {
    color: var(--ring-link-hover-color);
    background-color: var(--ring-hover-background-color);
  }
}
