@value ellipsis from '../../../../../ring-globals.css';
@value link from '../../../../common/BuildNumber/BuildNumber.css';
@value unit: var(--ring-unit);

.row,
.content {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1 1 auto;

  cursor: pointer;
}

.actions {
  margin-left: auto;
  padding-left: calc(unit * 2);
}

.problemMutesIcon,
.problemInvestigationsIcon {
  margin-right: calc(unit / 2);
}

.problemMutes,
.problemInvestigations {
  padding: 0;
}

.buildNumber .link {
  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
}

.entityPath {
  overflow: initial;
}

.buildStatus {
  color: var(--ring-text-color);
}

.buildNumber,
.buildStatus {
  margin-right: unit;
}

.comment {
  composes: ellipsis;
}

.new .comment,
.new .buildPathLinks,
.new .buildNumber,
.new .buildStatus {
  font-weight: bold;
}

.buildPath {
  composes: ellipsis;

  display: flex;
  align-items: baseline;
  flex: 1 1 auto;
  flex-wrap: nowrap;
}
