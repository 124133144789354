@value unit: var(--ring-unit);

.detailsButton {
  color: var(--ring-secondary-color);
}

.anchorButton {
  height: unset;
}

.dropdown {
  flex: 0 0 auto;

  font-size: var(--ring-font-size-smaller);

  line-height: 12px;
}

.loader {
  padding: 0 0 unit calc(unit * 2);
}

.item.item {
  color: inherit;
  border-radius: 0;

  &:hover > * {
    border: none;
  }
}

.hoverItem {
  background-color: var(--ring-selected-background-color);
}

.icon,
.itemText::before {
  margin-right: calc(unit / 2);
}
