@value unit: var(--ring-unit);

.wrapper {
  margin: 0 calc(unit * -4);
  padding-top: calc(unit * 2);

  &.smallPadding {
    margin: 0 calc(unit * -2);
  }
}
