@value ellipsis from '../../../ring-globals.css';

.wrapper {
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.agent,
.agentPool {
  composes: ellipsis;

  font-size: inherit;
  line-height: inherit;
}
