@value verticalCompensation: 2px;
@value unit: var(--ring-unit);

.item {
  position: relative;

  list-style-type: none;

  cursor: pointer;

  line-height: calc(unit * 3 - verticalCompensation);
}

.link {
  flex-grow: 1;

  border-radius: 0;
}

.link,
.innerLink {
  color: var(--ring-text-color);
}

.heading,
.link {
  display: flex;
  align-items: baseline;

  padding-right: unit;
  padding-bottom: verticalCompensation;

  &:hover {
    background: var(--ring-selected-background-color);
  }
}

.item:focus > .heading,
.link:focus {
  background: var(--ring-selected-background-color);
  box-shadow: inset 2px 0 var(--ring-main-color);
}

.item:focus,
.link:focus {
  outline: none;
}

/* stylelint-disable-next-line */
.interactive {}

.chevronIcon {
  position: absolute;
  top: 0;

  transition: color var(--ring-ease);

  color: var(--ring-icon-secondary-color);

  @nest .item:focus > .heading > &,
  .heading:hover > & {
    transition: none;

    color: var(--ring-icon-hover-color);
  }

  @nest .item:active > .heading:hover > & {
    transition: none;

    color: var(--ring-main-color);
  }

  @nest .interactive.interactive:hover ~ &,
  .interactive.interactive:active ~ & {
    transition: color var(--ring-ease);

    color: var(--ring-icon-secondary-color);
  }
}

.name {
  margin-right: unit;
  margin-left: calc(unit / 2);
}

.link .name {
  line-height: var(--ring-line-height-lower);
}

.innerLink {
  composes: name;
  composes: interactive;
}

.icon {
  color: rgba(154, 167, 176, 0.8);

  line-height: normal;
}

.size {
  margin-right: unit;

  white-space: nowrap;

  color: var(--ring-secondary-color);
}

.extensionIcon {
  composes: interactive;
}
