@value unit: var(--ring-unit);

.statusColumn,
.buildColumn,
.problemNameColumn,
.changesColumn,
.assignedColumn,
.actionsColumn {
  z-index: 2;

  padding: unit 0;
}

.statusColumn {
  display: flex;
  gap: unit;

  grid-column-start: status-start;
  grid-row-start: var(--row);
}

.buildColumn {
  display: flex;

  white-space: nowrap;
  grid-column-start: build-start;
  grid-row-start: var(--row);
}

.problemNameColumn {
  overflow: hidden;
  align-items: baseline;
  grid-column-start: problem-name-start;
  grid-row-start: var(--row);
}

.changesColumn {
  grid-column-start: changes-start;
  grid-row-start: var(--row);
}

.assignedColumn {
  grid-column-start: assigned-start;
  grid-row-start: var(--row);
}

.actionsColumn {
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.number {
  line-height: var(--ring-line-height);
}
