@value unit: var(--ring-unit);

.select button {
  color: var(--ring-text-color);
}

.selectedLabel {
  display: block;

  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest)
}
