@value label from '@jetbrains/ring-ui/components/input/input.css';
@value unit: var(--ring-unit);

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
