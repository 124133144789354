@value unit: var(--ring-unit);

.gap {
  position: absolute;
  top: calc(unit * -1);
  bottom: 0;

  width: 18px;

  color: var(--ring-sidebar-background-color);

  background:
    linear-gradient(135deg, common-gradient) -9px -9px,
    linear-gradient(45deg, common-gradient) 9px 9px,
    linear-gradient(-135deg, common-gradient) 9px 0,
    linear-gradient(-45deg, common-gradient) 9px 0;
  background-size: 18px 18px;

@value common-gradient: transparent 75%, var(--ring-line-color) 75%, currentColor 79%;
}

.previewGap {
  top: 1px;
  bottom: 1px;

  color: var(--ring-content-background-color);
}
