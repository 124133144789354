@value unit: var(--ring-unit);

.container {
  margin-bottom: calc(unit * 4);
}

.header {
  font-weight: bold;
}

.header:hover {
  background: var(--ring-hover-background-color);
}

.icon {
  color: var(--ring-icon-secondary-color);
}

.expandHandle {
  display: inline;

  height: auto;

  margin-right: calc(unit / 2);

  line-height: var(--ring-line-height-lower);
}
