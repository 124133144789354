@value unit: var(--ring-unit);

.dropdown {
  line-height: var(--ring-line-height);
}

.anchor {
  color: var(--ring-text-color);
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}
