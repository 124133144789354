@value unit: var(--ring-unit);

.actions {
  display: flex;

  align-items: baseline;

  height: calc(unit * 5);
  margin: 0 calc(unit * -0.5) 2px;

  & > * {
    margin: 0 calc(unit / 2);
  }
}

.plugins {
  display: flex;

  align-items: baseline;

  margin-right: 0;
  margin-left: auto;

  & > * {
    margin: 0 calc(unit / 2);
  }
}

.clear {
  padding-left: calc(unit / 2);
}
