.previewBarQueued {
  fill: var(--ring-line-color);
}


.previewBarCanceled {
  fill: var(--ring-icon-secondary-color);
}


.previewBarSuccess {
  fill: var(--tc-success-progress-color);
}

.previewBarFailed {
  fill: var(--ring-error-color);
}

.previewBarFocused {
  fill: var(--ring-main-color);
}


.previewBarFade {
  fill-opacity: 0.5;
}
