@value unit: var(--ring-unit);

.wrapper {
  position: relative;
  top: -1px;

  display: block;

  overflow: hidden;

  color: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ring-line-color);
  border-radius: 0 0 3px 3px;

  &:hover {
    color: inherit;
    border-color: var(--ring-border-hover-color);
  }
}

.messages {
  position: absolute;
  bottom: calc(unit / 2);

  width: 100%;
}

.message {
  cursor: pointer;
}

.info {
  position: absolute;
  bottom: calc(unit / 2);

  padding-left: calc(unit * 2);

  color: var(--ring-secondary-color);
}
