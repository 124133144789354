@value font, font-smaller from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.agentLink {
  composes: font;

  display: inline-flex;

  color: var(--ring-link-color);
}

.icon {
  flex-shrink: 0;

  width: calc(unit * 2);

  margin-right: 4px;

  text-align: center;

  color: var(--ring-secondary-color);
}

.secondary {
  composes: font-smaller;

  color: var(--ring-secondary-color);

  & .icon {
    color: var(--ring-icon-secondary-color);
  }
}

.withoutLink {
  display: inline-flex;
  align-items: baseline;

  max-width: 100%;
}

.onlyText {
  overflow: hidden;
}
