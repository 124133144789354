@value unit: var(--ring-unit);

.lastRunWrapper {
  display: flex;
  justify-content: center;
}

.lastRunTitle.lastRunTitle {
  margin: 0;
  padding: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.lastRun {
  max-width: 100%;
}

.lastRunChart {
  margin: 0 0 0 calc(unit * -1);
}
