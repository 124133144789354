@value unit: var(--ring-unit);

.placeholder,
.wrapper {
  background-color: var(--ring-popup-background-color);
}

.wrapper {
  padding: 0 calc(unit * 4);
}

.content {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  height: calc(unit * 4);

  padding-top: unit;

  padding-bottom: unit;

  color: var(--ring-secondary-color);

  border-bottom: 1px solid var(--ring-line-color);

  font-size: 14px;
}

.description {
  overflow: hidden;

  margin-left: auto;
  padding-left: calc(unit * 4);

  white-space: nowrap;
  text-overflow: ellipsis;

  line-height: 1.2em;
}

.actions {
  flex-shrink: 0;

  margin-left: auto;
}

.feedback {
  color: var(--ring-secondary-color);

  &:hover {
    color: var(--ring-secondary-color);
  }
}

.feedbackIcon {
  padding-right: calc(unit / 2);
  padding-left: unit;

  color: var(--ring-secondary-color);

  &:hover {
    color: var(--ring-secondary-color);
  }
}
