@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.wrrapper {
  display: flex;
}

.builds {
  position: relative;

  display: inline-flex;
}

.earliestWrapper {
  composes: font-smaller-lower;

  position: absolute;
  top: calc(unit * 4);
  right: 0;
  left: 0;

  overflow: hidden;

  height: var(--ring-line-height-lowest);

  pointer-events: none;

  &::before {
    display: inline-block;

    content: '';
  }
}

.earliest {
  white-space: nowrap;

  color: var(--ring-secondary-color);
}

.container .earliest {
  transition: opacity var(--ring-fast-ease);

  opacity: 0;
}

.container.hover .earliest,
.container:focus-within .earliest {
  transition: none;

  opacity: 1;
}

.loadingBar {
  align-self: flex-end;

  width: calc(unit * 1.5);

  transform-origin: bottom center;

  background-color: var(--ring-line-color);

  &:not(:first-child) {
    margin-left: 1px;
  }
}

@keyframes loading {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

.loadingBarAnimate {
  animation: loading 1s infinite alternate;
}

.loadingWithPreviousNextButton {
  margin: 0 calc(unit * 2 + (unit / 2));
}

.previousButton {
  margin-right: calc(unit / 2);
}

.nextButton {
  margin-left: calc(unit / 2);
}

.previousButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(unit * 2);
  height: 100%;

  & .icon {
    color: var(--ring-icon-secondary-color);
  }
}
