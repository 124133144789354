@value unit: var(--ring-unit);

.showAllContainer {
  margin-top: calc(unit * 1.75);
}

.toggle {
  width: 100%;
  padding: 0;

  text-align: left;
}

.options {
  margin-top: calc(unit * 1.75);
}
