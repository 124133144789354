@value unit: var(--ring-unit);

.actions {
  margin-bottom: unit;
}

.investigationMuteButtons {
  margin-right: unit;
}

.grid {
  --status-column: [start status-start] max-content;
  --name-column: [name-start] auto;
  --duration-column: [duration-start] max-content;
  --run-order-column: [run-order-start] max-content;
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: start;
  grid-column-gap: calc(unit * 2);
  grid-template-columns: var(--status-column) var(--name-column) var(--duration-column) var(--run-order-column) var(--actions-column) [end];
}

.testsList {
  display: contents;
}
