@value unit: var(--ring-unit);

.loadingTabs {
  display: inline-flex;

  height: calc(4 * unit);
  margin-left: calc(3 * unit);
  padding: 0;

  vertical-align: top;

  white-space: nowrap;

  color: var(--ring-secondary-color);

  border: none;
  outline: none;

  line-height: calc(4 * unit);
}
