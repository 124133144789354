@value unit: var(--ring-unit);

.node {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex-wrap: nowrap;

  line-height: var(--ring-line-height-lowest);
}

.detailedNode {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
}

.entityPath,
.buildNumber {
  line-height: inherit;
}

.entityPathLink {
  color: var(--ring-link-color);

  line-height: inherit;
}

.testPath {
  overflow: hidden;

  margin: calc(unit / 2) 0;
}

.childrenCount {
  margin-left: unit;

  user-select: none;

  white-space: nowrap;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.detailedNode .childrenCount {
  margin-left: unit;
}

.separator {
  flex-shrink: 0;

  color: var(--ring-secondary-color);
}
