@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.loading {
  cursor: progress;
}

.popup {
  overflow: hidden;

  width: 450px;

  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.heading {
  font-weight: bold;
}

.revision {
  margin-bottom: calc(unit * 2);

  font-family: var(--ring-font-family-monospace);
}

.vcsRoots {
  --vcs-root-column: [start vcs-root-name-start] auto;
  --path-column: [path-start] auto;

  display: grid;

  grid-column-gap: calc(unit * 2);
  grid-template-columns: var(--vcs-root-column) var(--path-column) [end];
}

.vcsRoot {
  display: contents;
}

.vcsRootNameColumn {
  grid-column-start: vcs-root-name-start;

  composes: ellipsis;
}

.currentVcsRoot {
  & .vcsRootNameColumn {
    font-weight: bold;
  }
}

.pathColumn {
  display: flex;
  overflow: hidden;
  justify-content: flex-end;

  color: var(--ring-secondary-color);
  grid-column-start: path-start;
}
