@value unit: var(--ring-unit);

.selectPopup {
  --ring-font-size: 14px;
}

.selectItem svg {
  width: 14px;
  height: 14px;
}

.selectItem div:first-child span {
  width: 13px;
}

.selectContainer {
  margin-right: calc(unit * 3);
}

.info {
  position: absolute;
  top: 2px;
  right: 0;
}

.hardwareDocLink {
  display: block;

  margin-top: calc(unit / 2);
}
