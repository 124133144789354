@value unit: var(--ring-unit);

.uploadForm {
  display: flex;
  flex-direction: column;
  row-gap: unit;
}

.portConfig {
  margin: calc(unit / 2) 0;
}
