@value timeWidth: 58px;
@value unit: var(--ring-unit);

.messages {
  font-family: var(--ring-font-family-monospace);
  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lower);
}

.messageItem.messageItem {
  display: block;
}

.hoverDisabled {
  & .messageFocusWrapper:hover {
    background-color: inherit;
  }
}

.grid {
  outline: none;
  background-color: var(--ring-content-background-color);
}

.time {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  padding-left: calc(unit * 2);
}

.message {
  margin-left: calc(unit * 2 + timeWidth - unit / 2);
}

.runningMessage {
  margin-top: calc(unit * 2);
}

.extraPadding {
  & .time {
    padding-left: calc(unit * 4);
  }

  & .message {
    margin-left: calc(unit * 4 + timeWidth - unit / 2);
  }
}
