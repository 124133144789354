@value unit: var(--ring-unit);

.layer {
  position: fixed;
  z-index: var(--ring-overlay-z-index);
  top: 0;
  left: 0;

  pointer-events: none;
}

.content {
  padding: 0 calc(unit * 2);

  border: 1px solid var(--ring-line-color);
  border-radius: var(--ring-border-radius);
  background: var(--ring-content-background-color);
}
