@value font-smaller from '../../../../ring-globals.css';
@value exactDuration, exactStarted from '../../BuildDuration/BuildDurationAnchor/BuildDurationAnchor.css';

.buildsHeader {
  composes: font-smaller;

  display: contents;

  & > * {
    margin-bottom: var(--ring-unit);
  }

  color: var(--ring-secondary-color);
}

.oneLine {
  composes: oneLine from '../Build/Build.css';
}

.number {
  composes: number from '../Build/Build.css';
}

.branch {
  composes: branchPath from '../Build/Build.css';

  box-sizing: border-box;
}

.status {
  grid-column-start: status-start;
}

.changes {
  composes: changes from '../Build/Build.css';
}

.agent {
  composes: agent from '../Build/Build.css';
}

.durationWrapper {
  composes: duration from '../Build/Build.css';

  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.started {
  composes: exactStarted;
}

.duration {
  composes: exactDuration;

  text-align: end;
}

.icons {
  composes: icons from '../Build/Build.css';
}
