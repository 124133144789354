@value awaits-color, refuse-color, approved-color from '../BuildApproval.css';
@value unit: var(--ring-unit);

.awaits {
  color: awaits-color;
}

.refused {
  color: refuse-color;
}

.approved {
  color: approved-color;
}

.readonly {
  color: var(--ring-secondary-color);
}

.popup {
  min-width: calc(unit * 35);
}

.popup.withControls {
  min-width: calc(unit * 43);
}

.popupContent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  padding: calc(unit * 2);
}

.approve {
  margin-left: calc(unit * 2);
}
