@value unit: var(--ring-unit);

.arrow {
  position: absolute;

  display: block;
}

.tip {
  position: absolute;
}

.tipLeft {
  top: calc(unit * 24);

  max-width: calc(unit * 23);

  text-align: left;

  & .arrow {
    top: calc(unit * -6);

    width: calc(unit * 7 + 1px);
    height: 45px;
  }
}

.container {
  display: inline-flex;
  flex-direction: column;
}

.description {
  max-width: calc(unit * 80);
}

.installAgent {
  align-self: center;

  margin-top: unit;
}
