@value button-primary-background-color: #1a98ff;
@value button-primary-border-color: #0062b2;
@value unit: var(--ring-unit);

.icon {
  position: absolute;
  top: 0;
  right: 2px;

  transition: color var(--ring-ease);

  color: var(--ring-secondary-color);
}

.triangle {
  position: absolute;
  top: 0;
  left: 0;

  width: calc(unit * 7);
  height: calc(unit * 3.5);

  transition: background-color var(--ring-ease), box-shadow var(--ring-ease);

  transform: rotate(45deg);
  transform-origin: 60% 51%;

  background-color: var(--ring-line-color);
}

.turnOn {
  & .icon {
    color: var(--ring-white-text-color);
  }

  & .triangle {
    background-color: var(--ring-main-color);
  }
}

.container {
  position: absolute;
  top: 0;
  right: 0;

  overflow: hidden;

  width: calc(unit * 5);

  height: calc(unit * 5);
}

.link {
  outline: none;

  &:hover,
  &:focus {
    box-shadow: none;

    & .icon,
    & .triangle {
      transition: none;
    }

    & .icon {
      color: var(--ring-white-text-color);
    }

    & .triangle {
      background-color: var(--ring-main-hover-color);
    }
  }

  &:active .triangle {
    background-color: button-primary-background-color;
    box-shadow: inset 0 -1px button-primary-border-color;
  }
}
