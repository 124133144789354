.linkIcon {
  margin-right: calc(var(--ring-unit) / 2);

  color: var(--tc-icon-main-color);

  line-height: normal;
}

.container {
  display: flex;
  overflow: hidden;
  align-items: flex-end;
}

.title {
  flex-grow: 1;
}

.favorite {
  display: inline-flex;
}

.favoriteButton {
  margin-right: var(--ring-unit);
  margin-left: auto;
}
