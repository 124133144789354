@value unit: var(--ring-unit);

.tabToolbar {
  display: flex;
  align-items: center;

  height: calc(4.25 * unit);

  margin-bottom: calc(unit * 2);
}
