@value unit: var(--ring-unit);

.section {
  margin: calc(unit * 2) 0;
}

.subtitle {
  margin-top: 0;
  margin-bottom: unit;

  font-size: var(--ring-font-size);
  font-weight: 600;
  line-height: var(--ring-line-height);
}

.text {
  margin-top: calc(unit / 4);
  margin-bottom: calc(unit / 4);
  padding: 0;
}

.valuesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;

  margin-top: calc(unit - unit / 4);
}

.values.values {
  display: flex;
  flex-wrap: nowrap;
}

.value {
  display: flex;
  flex: 1;
  justify-content: center;

  padding-top: 1px;

  font-family: "Twemoji Mozilla",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
  "EmojiOne Color",
  "Android Emoji",
  sans-serif;
}

.hints {
  display: flex;
  justify-content: space-between;

  padding: calc(unit / 4) 0;
}

.hint {
  display: block;

  margin: 0;
  padding: 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.tags {
  display: flex;
  flex-wrap: wrap;

  margin-top: calc(var(--ring-unit) / 2);
}

.tag {
  margin-right: calc(unit / 2);
  margin-bottom: calc(unit / 2);

  &.active {
    background: var(--ring-hover-background-color);
    box-shadow: inset 0 0 0 1px var(--ring-main-color);
  }
}


.textarea {
  width: 100%;

  resize: none;
}

.textarea.textarea {
  margin-top: calc(unit / 2);
}

.controls {
  display: flex;

  margin-top: calc(unit * 1.5);
}

.submit {
  margin-right: unit;
}

.more {
  margin-right: 0;
  margin-left: auto;
  padding-right: 0;
}

.error {
  color: var(--ring-error-color);
}
