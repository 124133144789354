@value unit: var(--ring-unit);

.differenceMark {
  position: absolute;
  top: calc((var(--ring-line-height) - unit) / 2);
  left: calc(unit * -1.5);

  display: block;

  width: unit;
  height: unit;

  border-radius: unit;

  background-color: var(--ring-link-hover-color);
}
