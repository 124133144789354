.inactiveCircle {
  stroke-width: 1;
  stroke: var(--ring-secondary-color);
  fill: none;
}

.activeCircle {
  z-index: 1;
  stroke-width: 3;
  stroke: var(--ring-main-color);
  fill: none;

  animation: circle-loader 1s ease-in;
}

.stopAnimation {
  animation: none;
}

@keyframes circle-loader {
  0% {
    stroke-dasharray: 0 100;
  }
}

.greenActive {
  stroke: var(--ring-success-color);
}

.yellowActive {
  stroke-width: 3;
  stroke: var(--ring-warning-color);
}

.blueInactive {
  stroke: var(--ring-search-color);
}
