@value unit: var(--ring-unit);

.name {
  overflow: hidden;

  margin-bottom: calc(unit * 0.25);

  white-space: nowrap;
  text-overflow: ellipsis;

  line-height: var(--ring-line-height);
}

.newFailure {
  font-weight: bold;
}

.parameters {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.full {
  white-space: normal;
  overflow-wrap: break-word;
}
