@value unit: var(--ring-unit);

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-height: calc(3 * unit);

  outline: none;
}

.item:hover {
  background-color: var(--ring-hover-background-color);
}

.projectItem:hover {
  cursor: pointer;
}

.itemLeftColumn {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
}

.itemName {
  flex: 0 0;

  white-space: nowrap;
}

.itemTypeIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-secondary-color);
}

.chevronIcon {
  position: relative;

  display: inline;

  cursor: pointer;
}

.itemLink {
  margin-right: calc(2 * unit);
}

.disabledIcon {
  visibility: hidden;
}

.incompatibilitiesPopup {
  padding: unit;
}

.incompatibility {
  overflow: hidden;

  flex: 1 1;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: var(--ring-font-size-smaller);
}

.incompatibilityLine {
  display: block;
}

.incompatibilityPropName {
  color: var(--ring-secondary-color);

  font-family: var(--ring-font-family-monospace);
}

.incompatibilityPropValue {
  color: var(--ring-error-color);

  font-family: var(--ring-font-family-monospace);
}

.showAllIncompatibilitiesButton {
  flex: 0 0;

  white-space: nowrap;

  color: var(--ring-secondary-color);
}

.assignItemButton {
  flex: 0 0;
}
