@value unit: var(--ring-unit);

.col {
  padding: 0 unit;
}

.title1.title1,
.title2.title2 {
  margin: 0;
  padding: 0;

  font-size: var(--ring-font-size-smaller);
}

.title1.title1 {
  padding-top: calc(unit * 2);
  padding-bottom: unit;

  letter-spacing: 2px;
  text-transform: uppercase;

  border-bottom: 1px solid var(--ring-line-color);

  font-weight: normal;

  line-height: var(--ring-line-height-lowest);
}

.title2.title2 {
  font-weight: bold;
  line-height: var(--ring-line-height);
}
