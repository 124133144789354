.buildPath {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.separator {
  flex-shrink: 0;

  color: var(--ring-secondary-color);
}
