@value dotsAnimation, dots from '../BuildStatus/BuildStatusLink/BuildStatusLink.css';
@value unit: var(--ring-unit);

.text {
  line-height: var(--ring-line-height);

  &.majorInfo {
    color: var(--ring-text-color);
  }

  &.minorInfo {
    color: var(--ring-secondary-color);
  }

  &.error {
    color: var(--ring-error-color);
  }

  &.success {
    color: var(--ring-success-color);
  }

  &.warning {
    color: var(--ring-warning-color);
  }
}

.animated .animated::after {
  content: '';
  animation: dotsAnimation;
}

.showButton {
  margin-left: calc(unit / 4);
}
