@value font-smaller from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.metadataItem {
  margin-bottom: calc(unit / 2);

  &:last-child {
    margin-bottom: 0;
  }
}

.toggleOtherButton {
  composes: font-smaller;

  margin-top: calc(unit / 2);

  padding: 0;

  color: var(--ring-secondary-color);
}
