@value unit: var(--ring-unit);

.repositories {
  width: calc(unit * 58);
  height: 100%;

  padding-top: calc(unit * 2.5);

  border-right: 1px solid var(--ring-line-color);
}

.error {
  color: var(--ring-error-color);
}

.actions {
  margin-top: calc(unit * 2);
}

.button {
  margin-right: unit;
}
