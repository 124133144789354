@value unit: var(--ring-unit);

.container {
  padding: 0 calc(unit * 2);
}

.list,
.item {
  display: contents;
}
