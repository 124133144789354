@value glyph from '@jetbrains/ring-ui/components/icon/icon.css';

.icon {
  margin-top: -2px;
  margin-left: var(--ring-unit);

  color: var(--ring-disabled-color);
}

.icon .glyph {
  vertical-align: -5px;
}
