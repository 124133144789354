@value ellipsis, resetButton from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.legacyPopupControl {
  display: inline-flex;
  align-items: baseline;

  max-width: 100%;

  white-space: nowrap;

  line-height: normal;
}

.label {
  composes: ellipsis;

  min-width: 0;

  padding-bottom: 1px;
}

.toggle {
  composes: resetButton;

  margin-left: calc(unit / 2);
}
