.line {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:first-child {
    position: relative;
    z-index: 3;
  }
}

.filterControl {
  margin-right: var(--ring-unit);
  margin-bottom: var(--ring-unit);
}
