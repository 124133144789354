@value unit: var(--ring-unit);

.header {
  display: flex;
  align-items: center;

  margin-top: unit;
}

.search {
  width: 400px;
  margin-right: calc(unit * 2);
}
