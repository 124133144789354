@value unit: var(--ring-unit);

.popup {
  max-width: 300px;

  padding: unit calc(2 * unit);

  text-align: left;
}

.icon {
  color: var(--ring-icon-secondary-color);
}
