@value unit: var(--ring-unit);

.disconnected {
  color: var(--ring-error-color);
}

.disconnectedIcon {
  color: var(--ring-icon-error-color);
}

.idle {
  margin-right: calc(unit / 2);
}
