@value unit var(--ring-unit);

.grid {
  grid-template-columns: 138px auto;
  grid-column-gap: unit;
  grid-row-gap: calc(unit * 1.5);
}

.separator {
  border-bottom: 1px solid var(--ring-line-color);
  grid-column: span 2;
}

.projectScope {
  overflow: hidden;

  padding-right: calc(unit / 2);
}

.projectScopeDropdown {
  color: var(--ring-link-color);
}

.scopeDetails {
  color: var(--ring-secondary-color);
}

.investigationHint {
  padding: calc(unit * 1.5);

  white-space: pre-wrap;

  border-radius: 8px;
  background-color: var(--ring-sidebar-background-color);
  grid-column: span 2;
}

.relatedProjectsContainer {
  margin-top: calc(unit * 1.5);
}

.relatedProjects {
  margin: 0;
  padding-left: calc(unit * 2);

  list-style-type: '\2022';

  color: var(--ring-link-color);
}

.relatedProjects li {
  padding-left: unit;
}
