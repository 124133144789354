@value unit: var(--ring-unit);

.categoryTitle {
  margin-top: calc(unit * 3);
  margin-bottom: 0;
  padding: 0 calc(unit * 4);

  color: var(--ring-heading-color);

  font-size: 20px;

  font-weight: normal;
  line-height: 24px;
}
