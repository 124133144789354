@value unit: var(--ring-unit);

.row {
  display: contents;

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.expanded {
  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: calc(unit * -4);
    bottom: 0;
    left: calc(unit * -4);

    content: '';
    pointer-events: none;

    box-shadow:
      0 0 0 1px var(--ring-line-color),
      0 2px 8px var(--ring-popup-shadow-color);

    grid-column: start / end;
    grid-row: var(--row) / span 2;
  }
}

.details {
  display: contents;
}

.button {
  &::before {
    position: absolute;
    top: 0;
    right: calc(unit * -4);
    bottom: 0;
    left: calc(unit * -4);

    content: '';
  }

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.button:focus {
  outline: none;
}

.expanded .button {
  @nest &::before {
    border-bottom: 1px solid var(--ring-line-color);
    background-color: var(--ring-hover-background-color);
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

.details:hover .button,
.details .button:focus {
  @nest &::before {
    background-color: var(--ring-hover-background-color);
  }
}

.detailsWrapper {
  grid-column: start / end;
}

.arrow {
  position: absolute;

  grid-column: -1;
  grid-row: var(--row) / span 1;

  top: calc(var(--absolute-child-top) + 8px);
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: var(--ring-icon-secondary-color);

  line-height: normal;

  @nest .details:hover &,
  .button:focus ~ &{
    transition: none;

    opacity: 1;
  }

  @nest .button:active ~ & {
    transition: none;

    opacity: 1;
    color: var(--ring-main-color);
  }
}

.expanded .arrow {
  opacity: 1;
}
