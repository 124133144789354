@value label from '@jetbrains/ring-ui/components/input/input.css';
@value unit: var(--ring-unit);

.inputContainer .label {
  margin-bottom: unit;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.inputContainer:not(:first-child) {
  margin-top: calc(unit * 1.5);
}

.popup {
  margin-top: calc(-0.5 * unit);
}
