@value unit: var(--ring-unit);

.filter {
  display: flex;

  overflow: hidden;
  align-items: baseline;
  flex-wrap: wrap;
}

.state {
  margin-right: calc(unit * 2);
}

.parameters {
  margin-right: calc(unit * 2);
}
