@value unit: var(--ring-unit);

.dockerIntegrationForm > div:first-child {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.testDockerConnectionBlock {
  display: flex;
  column-gap: calc(unit * 1.5);

  align-items: center;
  flex-wrap: wrap;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.testDockerConnection {
  padding: 0;
}

.success {
  color: var(--ring-success-color);
}

.error {
  color: var(--ring-error-color);
}
