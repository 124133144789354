@value unit: var(--ring-unit);

.loader {
  display: block;

  width: 16px;

  margin: 0 calc(4 * unit);
}

.dialog {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.dialogContent {
  display: flex;
  flex: 1 0;
  flex-direction: column;

  width: calc(unit * 66);
  height: calc(100vh - unit * 8);
  max-height: calc(unit * 72);
}

.header.header {
  flex: 0 0;

  margin: calc(2 * unit) calc(4 * unit);

  font-size: calc(3 * unit);
}

.searchInput {
  flex: 0 0;

  margin-right: calc(4 * unit);
  margin-left: calc(4 * unit);
}

.tree {
  display: flex;
  flex: 1 0;

  margin-top: calc(0.5 * unit);
}

.emptyTree {
  flex: 1 0;

  padding: 0 calc(4 * unit);
}

.treeItem {
  height: calc(3 * unit);

  cursor: default;

  line-height: calc(3 * unit);
}

.treeItem:hover {
  background-color: var(--ring-hover-background-color);
}

.treeItemProject {
  padding: 0 calc(3.75 * unit);
}

.treeItemAction {
  flex: 0 0;
}

.treeItemAction[disabled] {
  cursor: default;
  pointer-events: all;
}

.panel.panel {
  flex: 0 0;

  padding-left: calc(4 * unit);

  border-top: 1px solid var(--ring-popup-border-color);
}

.diassociateCheckbox {
  display: block;

  margin-bottom: calc(2 * unit);
}
