@value unit: var(--ring-unit);

.buttons {
  position: relative;

  margin-top: calc(4 * unit);
}

.button {
  margin-right: unit;
}
