@value unit: var(--ring-unit);

.row {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: calc(1.5 * unit);
}

.expandCollapse {
  margin-left: unit;

  font-size: inherit;
}
