@value font-smaller from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.buildNumber {
  composes: font-smaller;
  composes: wrapper from '../StarBuild/StarBuild.css';
  composes: link from '../LinkWithIcon/LinkWithIcon.css';

  position: relative;

  color: var(--ring-text-color);
}

.myPersonal {
  font-weight: bold;
}

.personal,
.canceled {
  color: var(--ring-secondary-color);
}

.canceled,
.canceled:hover {
  text-decoration: line-through;
}

.text {
  composes: text from '../LinkWithIcon/LinkWithIcon.css';
}

.starIcon {
  padding: 0 calc(unit / 2);
}

.numberWithComment {
  min-width: 0;
}

.comment,
.warning {
  margin-left: calc(unit / 2);
}

.link {
  width: 100%;
  min-width: 0;
}

.moveToTop.moveToTop {
  margin-left: calc(unit * -1);
  padding-right: 3px;
}
