@value unit: var(--ring-unit);

.tipsContainer {
  display: flex;
  flex-wrap: wrap;
}

.panel {
  display: flex;
  flex-direction: column;

  width: calc(unit * 36);
  min-height: calc(unit * 20);
  margin: unit 0 calc(unit * 2) calc(unit * 2);
}

.popup {
  z-index: 10;
}

.category.category:not(.categoryCollapsed) {
  color: var(--ring-content-background-color);
}

.categoryCollapsed.categoryCollapsed {
  color: currentColor;
}

.category:not(.categoryCollapsed, .categorySelected) {
  color: var(--ring-content-background-color);

  /* stylelint-disable-next-line selector-max-specificity  */
  &:hover {
    color: #bedaf4;
    box-shadow: none;
  }
}

.categorySelected:not(.categoryCollapsed),
.categorySelected.categorySelected:hover,
.categorySelected.categorySelected:active {
  box-shadow: inset 0 -3px 0 0 white;
}
