.placeholder {
  white-space: pre-wrap;
}

.inline {
  overflow: hidden;

  white-space: nowrap !important;
  text-overflow: ellipsis;
}
