@value unit: var(--ring-unit);

.errorBubblePopup {
  overflow: visible;

  margin-right: 10px;

  border: none;

  box-shadow: none;
}

.errorBubble {
  box-sizing: border-box;
  min-height: calc(unit * 3);
  padding-right: calc(unit * 2);
  padding-left: calc(unit * 2);

  white-space: nowrap;

  border: 1px solid var(--ring-popup-border-color);
  border-radius: var(--ring-border-radius);

  background: var(--ring-popup-background-color);
  box-shadow: var(--ring-popup-shadow);

  font-size: 12px;
  line-height: 22px;
}

.errorBubble::before {
  position: absolute;
  top: unit;
  right: -4px;

  display: block;

  width: unit;
  height: unit;

  content: '';
  transform: rotate(226deg);

  border: 1px solid var(--ring-popup-border-color);
  border-top: none;
  border-right: none;
  background: var(--ring-popup-background-color);
}
