@value unit: var(--ring-unit);

.status {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  margin-right: calc(unit / 2);
}

.success {
  color: var(--ring-success-color);
}

.failed {
  color: var(--ring-error-color);
}

.muted,
.ignored {
  color: var(--ring-text-color);
}
