.previewContainer {
  overflow: hidden;

  height: 32px;

  margin-bottom: var(--ring-unit);

  cursor: pointer;

  border-radius: var(--ring-border-radius);
  background-color: var(--ring-sidebar-background-color);
  box-shadow: 1px 1px 0 0 var(--ring-line-color) inset, -1px -1px 0 0 var(--ring-line-color) inset;
}

.preview {
  position: relative;
}

.brush {
  width: 100%;
  height: 100%;

  pointer-events: none;

  border-radius: var(--ring-border-radius);
  background: var(--ring-content-background-color);
  box-shadow: 0 0 0 1px var(--ring-main-color) inset;
}

.victoryContainer > svg {
  overflow: visible;
}
