@value unit: var(--ring-unit);

.agentToggle {
  position: relative;

  display: inline-block;
}

.comment {
  width: 100%;
}

.commentIcon {
  margin-right: unit;
}

.timerIcon {
  margin-right: unit;
}

.restoreTimes {
  margin-left: calc(0.5 * unit);
}

.timeoutSelect {
  z-index: 101;
}
