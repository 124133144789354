@value unit: var(--ring-unit);
@value ellipsis from '../../../../../../../ring-globals.css';

.container {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.status {
  padding: 3px 0;

  color: var(--ring-error-color);
}

.arrow {
  padding-left: calc(unit/4);

  color: var(--ring-icon-color);
}

.configuration {
  display: flex;
}

.configurationButton {
  width: 100%;
  height: calc(unit * 2);
  padding: 0;

  color: var(--ring-link-color);

  line-height: var(--ring-line-height-lowest);

  &:hover {
    .arrow {
      color: var(--ring-link-hover-color);
    }
  }
}

.noFailures {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height);
}

.configurationContent {
  display: flex;

  width: 100%;
}

.configurationText {
  composes: ellipsis;

  display: inline-block;

  max-width: 100%;
}
