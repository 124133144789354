@value unit: var(--ring-unit);

.cell {
  display: flex;

  align-items: baseline;

  height: calc(3 * unit);

  user-select: none;

  line-height: calc(3 * unit);
}

.mutedName {
  color: var(--ring-secondary-color);
}

.chevron {
  color: var(--ring-icon-secondary-color);
}

.label {
  margin: 0 calc(0.5 * unit);

  white-space: nowrap;

  color: var(--ring-secondary-color);
}
