@value unit: var(--ring-unit);

.row {
  display: flex;
  align-items: baseline;

  margin: unit 0;

  & > :not(:last-child) {
    margin-right: calc(unit * 2);
  }
}

.tagsFiltersWrapper {
  max-width: 100%;
  min-height: calc(unit * 3 + 1px);
}

.locator.locator {
  align-self: flex-start;
  flex-grow: 1;

  margin-bottom: 9px;
}

.starPinRow {
  margin-bottom: calc(unit * -2);

  & > :not(:last-child) {
    margin-right: calc(unit * 2);
  }
}

.projects {
  width: 330px;

  & :global(.searchPanel) {
    display: inline-block;

    width: 100%;
  }

  & > search-panel {
    vertical-align: -1px;
  }
}

.toggle {
  flex-shrink: 0;

  margin-left: auto;
}

.controls {
  composes: row;

  flex-wrap: wrap;
}

.controls > * {
  margin-top: unit;
}

.newWindowLink {
  margin-right: calc(unit * -1);
  margin-left: auto;
}

.update {
  position: relative;

  margin-left: auto;

  white-space: nowrap;
}

.loaderBlock {
  position: absolute;
  top: 2px;
  right: calc(unit * 2);

  opacity: 0;
}

.loader {
  margin-right: calc(unit / 2);
}

.showLoader .loaderBlock {
  transition: opacity 0.3s ease-in;

  opacity: 1;
}

.showLoader .updateButton {
  transition: opacity 0.3s ease-in;

  opacity: 0;
}

.toggle + .update {
  margin-left: 0;
}

.tagsRow {
  margin-top: 0;
}

.agentPattern {
  align-self: flex-start;
}

.stateIcon {
  vertical-align: -1px;
}

.inactiveIcon:not(:hover) .stateIcon {
  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}
