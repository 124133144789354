.wrapper {
  outline: none;

  &:hover {
    color: inherit;
    background-color: var(--ring-hover-background-color);
  }

  &.wrapper:focus {
    background-color: var(--ring-hover-background-color);
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}
