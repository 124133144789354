@value patternSize: unit;
@value unit: var(--ring-unit);

.column {
  position: relative;

  width: calc(unit * 1.5);

  transition: background-color var(--ring-fast-ease);

  &::before {
    position: absolute;
    z-index: var(--ring-fixed-z-index);
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;

    content: '';

    border-radius: 2px;
  }
}

.half {
  min-height: 1px;
}

.success {
  background-color: var(--tc-success-background-color);
  box-shadow: inset 0 1px var(--ring-icon-success-color);
}

.failed {
  background-color: var(--ring-icon-error-color);
}

.canceled {
  background-color: var(--ring-icon-secondary-color);
}

.timeInQueue {
  margin-top: 1px;

  background-color: var(--ring-line-color);
}

@keyframes bar-move {
  from {
    transform: translateY(calc(patternSize * -1));
  }

  to {
    transform: translateY(0);
  }
}

.running {
  overflow: hidden;

  &::before {
    display: block;

    height: calc(100% + patternSize);

    content: '';

    background-repeat: repeat;
    background-position: bottom;
    background-size: patternSize patternSize;
  }

  &.success {
    &::before {
      background-image:
        linear-gradient(
          135deg,
          var(--ring-icon-success-color),
          var(--ring-icon-success-color) 3.75%,
          transparent 3.75%,
          transparent 46.25%,
          var(--ring-icon-success-color) 46.25%,
          var(--ring-icon-success-color) 53.75%,
          transparent 53.75%,
          transparent 96.25%,
          var(--ring-icon-success-color) 96.25%,
          var(--ring-icon-success-color)
        );
    }

    box-shadow: none;
  }

  &.failed::before {
    background-image:
      linear-gradient(
        45deg,
        var(--tc-error-background-color),
        var(--tc-error-background-color) 3.75%,
        transparent 3.75%,
        transparent 46.25%,
        var(--tc-error-background-color) 46.25%,
        var(--tc-error-background-color) 53.75%,
        transparent 53.75%,
        transparent 96.25%,
        var(--tc-error-background-color) 96.25%,
        var(--tc-error-background-color)
      );
  }
}

.animate::before {
  animation: bar-move 1s linear infinite;
}

.buildLink {
  display: block;

  outline: none;

  /* stylelint-disable-next-line selector-max-specificity */
  &.current .column::before {
    box-shadow: 0 0 0 3px rgba(0, 142, 255, 0.4);
  }

  /* stylelint-disable-next-line selector-max-specificity */
  &:focus .column::before {
    box-shadow: 0 0 0 3px var(--ring-border-hover-color);
  }

  &:not(:first-child) {
    margin-left: 1px;
  }
}


.hovered {
  --tc-hovered-stats-bar-success: #bcdcc2;

  & .column {
    transition: none;
  }

  & .success {
    background-color: var(--tc-hovered-stats-bar-success);
  }

  & .failed {
    background-color: var(--ring-error-color);
  }

  & .canceled {
    background-color: var(--ring-secondary-color);
  }

  & .timeInQueue {
    background-color: var(--ring-borders-color);
  }
}

.popup {
  padding: unit calc(unit * 3) calc(unit * 2);
}
