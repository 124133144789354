@value ellipsis from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.row {
  display: contents;
}

.row::after {
  position: absolute;

  z-index: -1;
  top: calc(var(--absolute-child-top) - 1px);
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border-bottom: 1px solid var(--ring-line-color);
  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.row:first-child::after {
  border-top: 1px solid var(--ring-line-color);
}

.vcsRootColumn {
  display: flex;
  grid-column-start: vcs-root-start;
  grid-row-start: var(--row);
}

.revisionsColumn {
  grid-column-start: revisions-start;
  grid-row-start: var(--row);
}

.vcsRootColumn,
.revisionsColumn {
  overflow: hidden;

  padding-top: 4px;
  padding-bottom: 6px;
}

.revision {
  padding-top: 4px;
  padding-bottom: 6px;
}

.revision:first-child {
  padding-top: 0;
}

.revision:last-child {
  padding-bottom: 0;
}

.revisionMainContent {
  display: flex;
  align-items: baseline;
}

.branchAndLabels {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  margin-top: calc(unit / -2);
}

.vcsBranchName {
  overflow: hidden;
}

.vcsLabel {
  display: flex;
  align-items: baseline;
}

.vcsLabelIcon {
  margin-right: calc(unit / 2);
}

.vcsLabelsError {
  margin-top: calc(unit / 2);

  color: var(--ring-error-color);
}

.vcsBranchName,
.vcsLabel {
  margin-top: calc(unit / 2);
  margin-right: calc(unit * 1.5);
}

.hash {
  display: flex;
  overflow: hidden;

  flex: 0 0 auto;
  justify-content: flex-end;

  max-width: 200px;

  margin-left: auto;
  padding-left: unit;
}

.version {
  composes: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.versionCopyButton {
  opacity: 1;
}

.vcsNameAndVcsRootName {
  overflow: hidden;
  overflow-wrap: break-word;
}

.vcsName,
.vcsRootName,
.version {
  line-height: var(--ring-line-height);
}

.vcsGroupIcon {
  margin-right: calc(unit / 2);
}
