@value ellipsis from '../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.name {
  composes: ellipsis;
}

.suite,
.package,
.class {
  user-select: text;

  & > * {
    border-bottom-width: 0 !important;
  }
}

.path {
  display: inline;

  & .suite,
  & .package {
    margin-left: calc(unit / 2);

    font-size: var(--ring-font-size-smaller);
  }

  &:not(:hover) .suite,
  &:not(:hover) .package {
    color: var(--ring-secondary-color);
  }
}
