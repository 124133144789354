@value loader from '../../../../BuildPage/DependenciesTab/DependenciesChain/DependenciesChain.css';
@value unit: var(--ring-unit);

.container {
  margin-bottom: 2px;

  border-bottom: 1px solid var(--ring-line-color);
}

.innerContainer {
  margin-bottom: calc(unit * -5);
}

.container .loader {
  padding-top: 0;
  padding-bottom: calc(unit * 7);
}
