@value unit: var(--ring-unit);

.dropwdown {
  margin-left: unit;

  white-space: nowrap;

  & button {
    padding-left: 0;
  }
}

.dropwdownActive {
  color: var(--ring-link-hover-color);
}

.popup {
  max-width: 600px;
  padding: unit calc(2 * unit) calc(2 * unit);

  color: inherit;
}

.chevron {
  margin-left: 2px;
}
