@value unit: var(--ring-unit);

.grid {
  --vcs-root-column: [start vcs-root-start] minmax(300px, max-content);
  --revisions-column: [revisions-start] auto;

  position: relative;
  grid-template-columns: var(--vcs-root-column) var(--revisions-column) [end];
  grid-column-gap: calc(unit * 2);
}
