@value font-smaller from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.comment {
  composes: font-smaller;

  flex: left;

  max-width: 742px;
  margin: 1px 32px 7px calc(unit * 3);

  white-space: pre-wrap;
}
