@value unit: var(--ring-unit);

.link {
  position: relative;

  display: inline-block;

  width: calc(unit * 1.5);
  height: calc(unit * 6 + 1px);
  margin-right: 1px;
}

.active::after {
  position: absolute;
  z-index: var(--ring-fixed-z-index);
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;

  content: '';

  border-radius: 2px;
  box-shadow: 0 0 0 3px rgba(var(--ring-main-components), 0.4);
}

.bar {
  position: absolute;
  right: 0;
  bottom: calc(unit * 3 + 1px);
  left: 0;

  min-height: 1px;
}

.success {
  background-color: var(--tc-success-background-color);
  box-shadow: inset 0 1px var(--ring-icon-success-color);
}

.failure {
  background-color: var(--ring-icon-error-color);
}

.canceled {
  background-color: var(--ring-icon-secondary-color);
}

@value patternSize: unit;

@keyframes bar-move {
  from {
    transform: translateY(calc(patternSize * -1));
  }

  to {
    transform: translateY(0);
  }
}

.running {
  overflow: hidden;

  &::before {
    display: block;

    height: calc(100% + patternSize);

    content: '';
    animation: bar-move 1s linear infinite;

    background-repeat: repeat;
    background-position: bottom;
    background-size: patternSize patternSize;
  }

  &.success {
    &::before {
      background-image:
        linear-gradient(
          135deg,
          var(--ring-icon-success-color),
          var(--ring-icon-success-color) 3.75%,
          transparent 3.75%,
          transparent 46.25%,
          var(--ring-icon-success-color) 46.25%,
          var(--ring-icon-success-color) 53.75%,
          transparent 53.75%,
          transparent 96.25%,
          var(--ring-icon-success-color) 96.25%,
          var(--ring-icon-success-color)
        );
    }

    box-shadow: none;
  }

  &.failure::before {
    background-image:
      linear-gradient(
        45deg,
        var(--tc-error-background-color),
        var(--tc-error-background-color) 3.75%,
        transparent 3.75%,
        transparent 46.25%,
        var(--tc-error-background-color) 46.25%,
        var(--tc-error-background-color) 53.75%,
        transparent 53.75%,
        transparent 96.25%,
        var(--tc-error-background-color) 96.25%,
        var(--tc-error-background-color)
      );
  }
}

.queued {
  position: absolute;
  top: calc(unit * 3 + 1px);
  right: 0;
  left: 0;

  min-height: 1px;

  background-color: var(--ring-line-color);
}

.link:hover .success {
  background-color: var(--tc-success-hover-background-color);
}

.link:hover .failure {
  background-color: var(--tc-error-hover-color);
}

.link:hover .canceled {
  background-color: var(--ring-secondary-color);
}

.link:hover .queued {
  background-color: var(--ring-borders-color);
}
