@value unit: var(--ring-unit);

.testMutesIcon,
.testInvestigationsIcon,
.testMetadataIcon,
.copyTestNameIcon,
.actionsAnchor {
  height: var(--ring-line-height-lowest);

  line-height: var(--ring-line-height-lowest);
}

.copyTestNameIcon {
  opacity: 0;

  &:focus {
    opacity: 1;
  }
}

.row {
  display: contents;

  line-height: 32px;

  &:hover .copyTestNameIcon {
    opacity: 1;
  }
}

.flakyLabel,
.testMetadataIcon {
  margin-left: unit;
  padding: 0;
}

.testMutes,
.testInvestigations {
  flex: 0 0 auto;

  padding: 0;
}

.testMutesIcon,
.testInvestigationsIcon {
  margin-right: calc(unit / 2);
}

.statusColumn,
.nameColumn,
.durationColumn,
.runOrderColumn,
.actionsColumn {
  z-index: 2;

  padding: unit 0;

  line-height: var(--ring-line-height-lowest);
}

.statusColumn {
  grid-column-start: status-start;
  grid-row-start: var(--row);

  display: flex;

  white-space: nowrap;
}

.invocation .statusColumn {
  padding-left: 22px;
}

.checkbox {
  padding: 0;

  white-space: nowrap;
}

.nameColumn {
  display: flex;

  overflow: hidden;
  align-items: baseline;
  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationColumn {
  grid-column-start: duration-start;
  grid-row-start: var(--row);

  text-align: right;
}

.runOrderColumn {
  grid-column-start: run-order-start;
  grid-row-start: var(--row);

  padding-left: unit;

  text-align: right;
}

.actionsColumn {
  grid-column-start: actions-start;
  grid-row-start: var(--row);
}

.actions {
  width: unset;
  margin-left: auto;
  padding: 0;
  padding-left: unit;
}
