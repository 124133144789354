@value unit: var(--ring-unit);

.description {
  margin-bottom: calc(unit * 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size);
  font-weight: normal;
  line-height: var(--ring-line-height);
}

.data {
  width: 100%;
}

.branchSelect {
  display: block;

  margin-bottom: calc(unit * 2);

  & > button {
    max-width: 100%;
  }
}

.preview {
  margin-bottom: calc(unit * 2);

  & .icon {
    display: block;
  }
}

.caption {
  display: block;

  margin-bottom: unit;

  font-size: 12px;
}

.formatSelect {
  margin-bottom: calc(unit * 2);
}

.warning {
  margin-top: unit;

  color: var(--ring-warning-color);
}

.warningIcon {
  margin-right: 4px;
}
