.statusColumn {
  grid-column-start: status-start;

  display: flex;

  white-space: nowrap;
}

.testNameColumn {
  grid-column-start: test-name-start;
}

.failuresColumn {
  grid-column-start: failures-start;
}

.assignedColumn {
  grid-column-start: assigned-start;
}

.actionsColumn {
  grid-column-start: actions-start;
}
