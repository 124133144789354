@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: var(--tc-content-width);
}

.svgContainer {
  max-width: calc(unit * 70);
  height: calc(unit * 50);

  margin: 0 auto;

  text-align: center;

  &.defaultUi {
    padding-top: calc(unit * 9);

    & > svg {
      margin-top: calc(unit * 8.5);
    }
  }
}

.infoContainer {
  max-width: calc(unit * 40);
  height: calc(unit * 30);

  margin: calc(unit * -4) auto 0;

  text-align: center;
}

.description {
  padding: 0 calc(unit * 2);
}

.benefits {
  margin-bottom: unit;
}
