@import '@jetbrains/ring-ui/components/global/variables.css';

@value unit: var(--ring-unit);

.popup {
  padding: calc(unit * 2) 0 calc(unit * 3);
}

.list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}
