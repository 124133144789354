@value unit: var(--ring-unit);

.filter {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;

  &:last-child {
    margin-right: 0;
  }
}

.stateButton.active .ignoredIcon,
.stateButton:hover .ignoredIcon,
.stateButton.active .mutedIcon,
.stateButton:hover .mutedIcon {
  color: var(--ring-text-color);
}

.stateButton.active .failedIcon,
.stateButton:hover .failedIcon {
  color: var(--ring-icon-error-color);
}

.stateButton.active .passedIcon,
.stateButton:hover .passedIcon {
  color: var(--ring-icon-success-color);
}

.stateIcon {
  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}

.filterLabel {
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit / 2);
}
