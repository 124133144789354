@value font from '../../../../ring-globals.css';

.link {
  composes: font;

  min-width: 0;
}

.success {
  color: var(--ring-success-color);
}

.failure {
  color: var(--ring-error-color);
}

.canceled {
  color: var(--ring-secondary-color);
}

.warning {
  color: var(--ring-warning-color);
}
