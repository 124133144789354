@value unit: var(--ring-unit);

.project {
  height: calc(3 * unit);

  user-select: none;

  outline: none;

  line-height: calc(3 * unit);
}

.expandableProject {
  cursor: pointer;
}

.indentedContainer {
  display: flex;
  align-items: baseline;
}

.chevron {
  flex: 0 0;
}

.chevronIcon.chevronIcon {
  color: var(--ring-icon-secondary-color);
}

.checkboxContainer {
  display: flex;
  overflow: hidden;
  align-items: baseline;

  margin-left: 3px;
}

.checkboxCell {
  top: 2px;

  flex-shrink: 0;

  margin-left: 1px;
}

.checkboxLabel.checkboxLabel {
  position: relative;

  z-index: 4;

  min-width: 0;

  margin-left: calc(unit / 2);

  cursor: pointer;

  line-height: calc(3 * unit);
}

.project:hover .pathItem {
  background-color: var(--ring-hover-background-color);
}

.projectAgentPools {
  overflow: hidden;
  flex: 1 1 calc(6 * unit);

  padding-left: unit;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
