.sidebar {
  position: absolute;

  z-index: 1; /* Need for correct scrolling work. See TW-60668 */

  overflow: hidden; /* Fix wrong page size in Edge */

  border-right: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);
}

.sidebarPlaceholder {
  flex-grow: 1;
}

.sidebarFixed {
  position: fixed;
}

.sidebarPanelWrapper {
  display: flex;
  flex: 1 1 auto;
}

.sidebarPanel {
  flex: 1 1 auto;
}

.resizable {
  display: flex;
  flex-direction: column;
}

.resizableRightHandle {
  z-index: 20;
}

.collapsedSidebar .sidebarPanelWrapper {
  flex-grow: 0;
}

.collapsedSidebar .footer {
  flex-grow: 1;

  border-top: none;
}
