@value unit: var(--ring-unit);

.input {
  display: inline-block;

  width: calc(unit * 35 + 2px);
  padding-bottom: calc(unit / 2);
}

.inputInner {
  height: 22px;
}

.toggle {
  color: var(--ring-link-color);
}

.container {
  display: flex;

  margin-top: calc(unit / 2);
}

.radioButton:not(:last-of-type) {
  padding: 0 0 8px;
}
