.assigneeBlock {
  overflow: hidden;

  align-content: start;
  flex-grow: 1;

  max-width: 305px;
  margin-right: 35px;
  margin-left: 29px;
  padding: 0;

  text-overflow: ellipsis;
}

.assignee {
  font-weight: normal;
}

.assigneeToCurrentUser {
  font-weight: bold;
}
