@value unit: var(--ring-unit);

.header {
  display: grid;

  overflow: hidden;

  grid-gap: calc(unit * 2);
  grid-template-columns: auto max-content;
  grid-template-rows: auto;
  grid-template-areas:
    'heading actions';

  margin: calc(unit * -2) calc(unit * -4) 0;
  padding: calc(unit * 2.5) calc(unit * 4) calc(unit * 2.5);

  border-bottom: 1px solid var(--ring-line-color);
}

.heading {
  grid-area: heading;
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: end;
}

.title.title {
  margin: 0;
}
