@value unit: var(--ring-unit);

.container {
  display: flex;
  justify-content: end;
  gap: calc(unit / 2);
}

.menu {
  padding: 0 calc(unit / 2);
}

.item {
  color: var(--ring-link-color);

  &:hover {
    color: var(--ring-link-hover-color);
  }
}
