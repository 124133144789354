@value unit: var(--ring-unit);
@value ellipsis from '../../../../ring-globals.css';

.container {
  display: flex;
  flex-direction: column;
  gap: unit;
}

.detail {
  word-break: break-word;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  &:not(:last-child){
    padding-bottom: calc(unit / 4);
  }
}

.assignment {
  composes: ellipsis;

  padding-bottom: calc(unit / 4);
}

.full {
  white-space: normal;
}

.scopeList {
  overflow: hidden;

  height: calc(unit * 2);

  margin-top: calc(unit / 4);
}

.entityPath {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.entityPath .entityPathLink {
  color: var(--ring-link-color);
}

.buildPath {
  overflow: hidden;

  margin: 0 calc(unit / 4);

  white-space: nowrap;
}
