@value font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.agentStatus {
  color: var(--ring-secondary-color);
}

.line {
  display: inline-flex;
  align-items: baseline;

  width: 100%;
}

@media (min-width: 960px) {
  .idleCommentLine {
    width: calc(100vw - unit * 21);
  }
}

@media (min-width: 1600px) {
  .idleCommentLine {
    max-width: calc(unit * 179);
  }
}

.link {
  min-width: 0;
}

.pool {
  composes: font-smaller;

  overflow: hidden;
  flex-shrink: 100000;

  min-width: calc(unit * 8);

  margin-left: unit;

  color: inherit;
}

.icon {
  flex-shrink: 0;

  width: calc(unit * 2);

  margin-right: calc(unit / 2);

  text-align: center;
}

.comment {
  composes: font-smaller;

  min-width: 0;

  color: var(--ring-text-color);
}
