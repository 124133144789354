.code {
  margin: 0;

  border: 1px solid transparent;
}

.row:hover .code {
  border-color: var(--ring-link-hover-color);
}

.wrap {
  position: relative;
}
