@value unit: var(--ring-unit);

.popup {
  min-width: calc(unit * 35);
  padding-left: calc(unit * 2);

  color: var(--ring-text-color);
  border: solid calc(unit / 8) var(--ring-line-color);
  background: var(--ring-popup-background-color);

  filter: drop-shadow(0 0 8px var(--ring-popup-shadow-color));

  box-shadow: none;

  &::before {
    box-shadow: none;
  }

  & :global(.ringMessageTitle) {
    font-weight: bold;
  }
}

.content {
  display: flex;
  flex-direction: column;

  color: var(--ring-text-color);
}

.withImage {
  padding: 0;

  & .content {
    padding: calc(unit * 2);
    padding-top: 0;

    color: var(--ring-text-color);
  }

  & .title {
    margin: unit 0;

    color: var(--ring-text-color);

    font-size: var(--ring-font-size-larger);
    line-height: var(--ring-line-height-lower);
  }

  & .image {
    width: 100%;
    max-height: calc(unit * 22.5);

    transform: translateY(calc(-1 * unit / 2));

    object-fit: cover;
  }
}

.helpLink {
  align-self: flex-start;

  padding-top: unit;
  padding-bottom: calc(unit / 2);

  color: var(--ring-main-color);
}

.bot {
  display: flex;
  align-items: center;

  margin-top: calc(unit * 1.5);

  & .btnPrev {
    margin-right: unit;
  }

  & .progress {
    margin-left: auto;
  }
}

.btnClose {
  position: absolute;
  top: calc(unit * 1.25);
  right: calc(unit * 1.25);

  & .closeIcon {
    color: var(--ring-secondary-color);
  }
}

.description {
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}
