@value unit: var(--ring-unit);

.container {
  display: flex;
  justify-content: end;
  gap: calc(unit / 2);
}

.menu {
  padding: 0 calc(unit / 2);
}
