@value font from '../../../ring-globals.css';

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.container {
  composes: font;

  display: flex;
  align-items: baseline;
}
