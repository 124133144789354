@value unit: var(--ring-unit);

.icon {
  position: relative;
  top: 1px;

  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.anchor {
  height: calc(unit * 2 + unit / 2);

  color: var(--ring-secondary-color);

  &:hover .icon {
    color: var(--ring-link-hover-color);
  }
}
