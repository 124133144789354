.statusColumn {
  grid-column-start: status-start;
}

.buildColumn {
  grid-column-start: build-start;

  display: flex;

  white-space: nowrap;
}

.problemNameColumn {
  grid-column-start: problem-name-start;
}

.changesColumn {
  grid-column-start: changes-start;
}

.assignedColumn {
  grid-column-start: assigned-start;
}

.actionsColumn {
  grid-column-start: actions-start;
}
