@value font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.buildInfo {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.buildBranch,
.buildChanges,
.startDate {
  composes: font-smaller;
}

.buildBranch,
.buildPath,
.buildChanges,
.startDate {
  margin-right: unit;

  line-height: var(--ring-line-height);

  &:last-child {
    margin-right: 0;
  }
}

.rerunCount {
  display: inline-block;
}

.rerunCount,
.flakyLabel {
  composes: font-smaller;

  margin-top: unit;
}

.metadata,
.buildLog {
  margin-top: calc(unit * 3);
}

.buildInfo,
.flakyLabel,
.metadata,
.buildLog {
  &:first-child {
    margin-top: 0;
  }
}

.buildLineTitle {
  margin-right: calc(unit / 2);
}

.withOverlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.buildPathLinks {
  color: var(--ring-link-color);

  line-height: var(--ring-line-height);
}

.separator {
  flex-shrink: 0;

  color: var(--ring-secondary-color);
}
