@value font-larger from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.overviewPlaceholder {
  position: relative;
}

.arrow {
  position: absolute;

  display: block;
}

.tip {
  position: absolute;
}

.tipLeft {
  top: calc(unit * 24);

  max-width: calc(unit * 23);

  text-align: left;

  & .arrow {
    top: calc(unit * -6);

    width: calc(unit * 7 + 1px);
    height: 45px;
  }
}

.tipRight {
  top: calc(unit * 6);
  right: 0;

  max-width: 190px;

  text-align: right;

  & .arrow {
    top: calc(unit * -7 + unit / 2);
    left: calc(unit * 15);

    width: calc(unit * 7);
    height: calc(unit * 8);
  }
}

.title {
  composes: font-larger;

  margin-top: calc(unit * 2);
}

.description {
  margin-top: unit;
}

.tipLeft,
.link {
  font-size: 14px;
}

.button {
  margin-bottom: calc(unit * 2);

  font-size: 12px;

  &:hover {
    text-decoration: none;

    color: var(--ring-content-background-color);
  }
}

.link {
  display: inline;

  margin-bottom: calc(unit * 1.5);
}

.linksContainer {
  display: inline-flex;
  flex-direction: column;

  margin-top: unit;
}
