@value unit var(--ring-unit);

.comment > div {
  height: 100%;
}

.comment,
.commentInput {
  min-height: 64px !important;
}

.commentLabelContainer {
  position: relative;

  height: 100%;
}

.commentLabel {
  position: absolute;
  top: 0;
}

.userPopup {
  z-index: 7;
}
