@value font-smaller-lower from '../../../../../ring-globals.css';

.button {
  composes: font-smaller-lower;

  margin-right: var(--ring-unit);
  padding: 0;

  color: var(--ring-secondary-color);
}
