@value unit: var(--ring-unit);

.note {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.blocks {
  margin-top: 26px;
}

.loadMore {
  display: block;

  width: calc(unit * 30);
  margin: unit auto 0;
}
