@value unit: var(--ring-unit);

.tree {
  position: relative;

  z-index: 0;

  margin-top: calc(unit * 2);
}

.investigationMuteButtons,
.expandAll {
  margin-right: calc(unit * 1.5);
}
