@value unit: var(--ring-unit);

.page {
  max-width: var(--tc-content-width);
}

.header .heading {
  overflow: hidden;

  margin: 0;
  margin-bottom: calc(unit * 2);

  word-break: break-word;

  &::first-letter {
    text-transform: uppercase;
  }
}

.filter {
  margin-bottom: calc(unit * 2);
}

.changeDetailsTabs {
  padding-bottom: calc(unit * 4);
}
