@value unit: var(--ring-unit);

.wrapper {
  line-height: var(--ring-line-height);
}

.content {
  max-width: 432px;
  padding: calc(unit * 2);
}

.visibleContainer {
  overflow: hidden;
}

.info {
  composes: info from '../../../../common/BuildInfoTable/BuildInfoTable.css';
}

.row {
  margin-bottom: calc(unit * 1.5);

  font-size: var(--ring-font-size);

  &:last-child {
    margin-bottom: 0;
  }
}

.buildQueueInfo {
  font-size: var(--ring-font-size);
}
