@value unit: var(--ring-unit);

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.error {
  animation: fade-in 0.2s;

  color: var(--ring-error-color);
}

span.error {
  margin-left: calc(unit * 2);
}
