@value unit: var(--ring-unit);

.authorizeAgent {
  display: inline-block;
}

.comment {
  width: 100%;
  margin-bottom: calc(1.5 * unit);
}

.poolSelectPopup {
  z-index: 101;
}

.authorizeAgent[disabled] {
  cursor: default;
  pointer-events: auto;
}

.authorizeAgent[disabled]:active {
  background-color: var(--ring-content-background-color);
}
