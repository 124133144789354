@value unit: var(--ring-unit);

.dialog {
  width: auto;
}

.dialogContent {
  padding-top: unit;
}

.expirationInfo {
  margin-top: calc(unit / 2);

  font-size: var(--ring-font-size-smaller);
}

.infoMargin {
  margin-top: unit;
}

.warning {
  color: var(--ring-warning-color);
}

.expired {
  color: var(--ring-error-color);
}

.renewalBtn {
  margin: 0 calc(unit / 2);

  color: var(--ring-button-primary-background-color);
}
