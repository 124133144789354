@value backgroundColor: #0077d6;

.containerClassName {
  padding: 0;

  background-color: backgroundColor;
}

.background {
  position: absolute;

  right: 0;
  bottom: 0;

  height: 140px;
}

.captionClassName {
  margin: 0;
}

.descriptionClassName {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.closeButtonClassName {
  & .icon {
    color: var(--ring-content-background-color);
  }

  &:hover .icon {
    opacity: 0.7;
    color: var(--ring-content-background-color);
  }
}
