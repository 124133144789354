@value copyrightWidth: calc(32 * unit);
@value sideColumnWidth: calc(100% / 2 - copyrightWidth / 2);
@value unit: var(--ring-unit);

.footer {
  max-width: calc((unit * 8) + var(--tc-content-width));
  min-height: calc(7 * unit);

  margin-top: calc(unit * 4);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  & a {
    color: inherit;
  }

  &.invisible {
    display: none;
  }

  &.centered {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.content {
  position: relative;

  margin: 0 calc(4 * unit);
  padding: unit 0;

  border-top: 1px solid var(--ring-line-color);
}

.version {
  width: sideColumnWidth;

  color: var(--ring-secondary-color);
}

.copyright {
  position: absolute;
  top: unit;
  right: 0;
  left: 0;

  width: copyrightWidth;

  margin-right: auto;
  margin-left: auto;

  text-align: center;
}

.links {
  position: absolute;
  top: unit;
  right: 0;

  width: sideColumnWidth;

  text-align: right;

  color: var(--ring-secondary-color);

  & a {
    margin-left: unit;

    white-space: nowrap;
  }
}

.extesnionsLinks {
  & a,
  & span {
    margin: 0;
  }

  & a {
    margin-left: unit;

    &:hover {
      color: var(--ring-link-hover-color);
    }
  }
}

.licenseExpiration {
  color: var(--ring-error-color);
}
