@value unit: var(--ring-unit);

.commentPopup {
  max-width: 300px;

  padding: unit calc(2 * unit);

  text-align: left;
}

.info {
  color: var(--ring-secondary-color);
}

.text {
  overflow-wrap: break-word;
}
