@value unit: var(--ring-unit);

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: var(--tc-content-width);
}

.tabs {
  composes: tabs from '../../../common/OverviewTab/OverviewTab.css';

  margin-top: calc(4 * unit);
}

.classicUiLink {
  display: inline-block;

  height: calc(4 * unit);
  margin: 0 calc(3 * unit);
  padding: 0;

  vertical-align: top;

  color: var(--ring-text-color);

  border: none;
  border-radius: 0;
  outline: none;

  line-height: calc(4 * unit);
}

.classicUiLink.classicUiLink:hover {
  text-decoration: none;

  color: var(--ring-link-hover-color);
  box-shadow: inset 0 -1px 0 0 var(--ring-link-hover-color);
}

.mammothIcon {
  vertical-align: middle;

  line-height: calc(2 * unit);
}
