@value unit: var(--ring-unit);

.verificationMessage {
  margin-bottom: calc(unit * 1.5);
}

.authorization {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit / 2);

  margin-bottom: calc(unit * 1.5);

  line-height: var(--ring-line-height);
}

.controls {
  display: flex;
  column-gap: unit;

  margin-top: calc(unit * 1.5);
}
