@value unit: var(--ring-unit);

.sidebar {
  position: relative;

  display: flex;

  overflow: hidden;
  flex-direction: column;

  min-height: calc(17.5 * unit);

  user-select: none;

  outline: none;
  background-color: var(--ring-sidebar-background-color);
}

.search {
  position: relative;

  flex: 0 1 auto;

  margin-top: unit;
  margin-right: calc(unit * 2);
  margin-bottom: calc(unit * 1.5);
  margin-left: calc(unit * 3.75);
}

.empty:not(:focus-within)::after {
  position: absolute;
  top: 0;
  right: calc(unit * 2);

  content: 'Q';

  color: var(--ring-disabled-color);

  line-height: calc(unit * 3);
}

.tree {
  flex: 1 1 auto;
}

.loader {
  padding-left: calc(unit * 3.5);

  line-height: calc(unit * 3);

  &:first-child {
    margin-top: 0;
  }
}

.loaderText {
  margin-left: 2px;
}
