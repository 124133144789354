.node {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex-wrap: nowrap;
}

.entityPath {
  line-height: 24px;
}

.entityPathLink {
  color: var(--ring-link-color);

  line-height: 24px;
}

.buildNumber {
  font-size: var(--ring-font-size);
  line-height: 24px;
}

.childrenCount {
  margin-left: var(--ring-unit);

  user-select: none;

  white-space: nowrap;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.separator {
  flex-shrink: 0;

  color: var(--ring-secondary-color);
}
