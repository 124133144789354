@value unit: var(--ring-unit);

.page {
  display: flex;
  flex-direction: column;

  padding-bottom: calc(unit * 4);
}

.pipelineList {
  min-width: calc(unit * 100);
  max-width: calc(unit * 180);
  margin: auto;
  padding-top: calc(unit * 2.5);
}
