@value unit: var(--ring-unit);

.container {
  position: relative;

  margin-top: calc(unit * 7);
}

.pannable {
  cursor: all-scroll;
}

.victoryContainer > svg {
  overflow: visible;
}
