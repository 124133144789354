@value unit: var(--ring-unit);

.recoveryKeysWithActions {
  display: flex;

  margin-top: unit;
}

.recoveryKeys {
  padding: calc(unit / 2) unit;

  white-space: pre;

  background: var(--ring-sidebar-background-color);

  font-family: monospace;
}

.actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-left: unit;
}
