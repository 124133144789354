@value unit: var(--ring-unit);

.content {
  width: calc(unit * 90);
  padding: calc(unit * 2);
}

.changes {
  margin-bottom: 0;
}
