@value unit: var(--ring-unit);

.psevdoPanel {
  position: sticky;

  top: -1px;
  left: 0;
}

.panel {
  position: sticky;
  z-index: 3;

  top: 0;
  left: 0;

  display: flex;
  align-items: baseline;

  padding: calc(unit * 2) calc(unit * 4);

  border-bottom: 1px solid transparent;
  background-color: var(--ring-sidebar-background-color);

  &.stuck {
     border-bottom: 1px solid var(--ring-line-color);
  }
}
