@value unit: var(--ring-unit);

.container {
  display: flex;

  width: calc(unit * 64.5);

  padding: unit calc(unit * 2);

  border-radius: var(--ring-border-radius);

  background-color: var(--ring-warning-background-color);

  font-size: var(--ring-font-size-smaller);
  column-gap: unit;
}

.info {
  background-color: var(--ring-hover-background-color);
}

.infoIcon {
  color: var(--ring-main-color);
}

.warningIcon {
  color: var(--ring-icon-warning-color);
}
