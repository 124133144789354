@value unit: var(--ring-unit);

.select {
  margin-right: calc(unit * 3);
  margin-left: calc(unit * 2);
}

.button {
  height: auto;

  color: var(--ring-header-link-color);
}
