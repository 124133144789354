@value unit: var(--ring-unit);

.label {
  font-size: var(--ring-font-size-smaller);
  font-weight: bold;
}

.descriptions {
  color: var(--ring-secondary-color);
}

.character {
  color: var(--ring-success-color);
}

.character:nth-child(2n + 1) {
  color: var(--ring-error-color);
}

.title {
  margin-right: calc(unit / 2);
}
