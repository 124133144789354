.hightlighting {
  pointer-events: initial;

  color: var(--ring-content-background-color);
  background-color: #ff8bcb;
}

.bright {
  background-color: var(--ring-link-hover-color);
}
