.arrowPopupMenu {
  display: inline-flex;
  align-items: baseline;

  max-width: 100%;

  white-space: nowrap;
  text-decoration: none;

  line-height: normal;
}

.anchor {
  padding: 0;
}
