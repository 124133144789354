.header.header {
  justify-content: flex-start;

  margin-bottom: var(--ring-unit);
}

.toggleContainer {
  display: block;

  margin-right: var(--ring-unit);
}
