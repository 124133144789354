@value unit: var(--ring-unit);

.heading {
  display: flex;
  align-items: baseline;
}

.headingIcon {
  margin-right: calc(unit / 2);
}

.investigationDetails {
  margin-bottom: calc(unit * 2);
}
