@value unit: var(--ring-unit);

.name {
  box-sizing: border-box;
}

.noRuns {
  color: var(--ring-secondary-color);
}

.nameLink {
  color: var(--ring-text-color);

  font-weight: bold;
}

.description > a {
  color: var(--ring-secondary-color);
}

.actions {
  padding: calc(unit / 2) 0;

  text-align: end;
}

.button {
  margin-left: unit;
}

.status {
  box-sizing: border-box;

  padding: 5px 0;
  grid-column-end: span 2;
}

.estimate {
  color: var(--ring-secondary-color);
}

.stop {
  margin-right: calc(unit * -1);
}

.hoverRow {
  top: 0;
  right: calc(unit * -4);
  bottom: 0;
  left: calc(unit * -4);
  grid-column: 1 / -1;
  grid-row-end: span 1;
}

.emptyRow {
  grid-column: 1 / -1;
}

.gap {
  margin-bottom: calc(unit / 2);
  padding-top: calc(unit / 2);

  border-bottom: 1px solid var(--ring-line-color);
  grid-column: 1 / -1;
}

.row {
  display: contents;
}

.row > * {
  position: relative;
}

.row > .hoverRow {
  position: absolute;
}

.row:hover .hoverRow,
.row .hoverRow:hover {
  background-color: var(--ring-hover-background-color);
}
