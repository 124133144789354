@value middle-ellipsis from '../../../../../../../common/MiddleEllipsis/MiddleEllipsis.css';

.entityPath,
.entityPathLink,
.buildStatus {
  line-height: inherit;
}

.buildStatus {
  margin-bottom: -1px;
}

.fixedTest {
  color: var(--ring-secondary-color);

  &,
  & .middleEllipsis {
    text-decoration: line-through;
  }
}

.newTest {
  font-weight: bold;
}
