@value unit: var(--ring-unit);
@value font-smaller-lower from '../../../../../ring-globals.css';

.placeholder {
  composes: font-smaller-lower;

  margin-top: calc(unit * 2);
  margin-left: calc(unit * 4);

  color: var(--ring-secondary-color);
}
