@value unit: var(--ring-unit);

.container {
  margin: 0 auto 0 calc(4 * unit);
  padding-top: unit;

  color: var(--ring-text-color);

  background-color: var(--ring-content-background-color);
}

.successIcon {
  color: var(--ring-success-color);
}

.successIcon,
.failureIcon {
  margin-right: unit;
}

.failedBuild {
  color: var(--ring-error-color);
}
