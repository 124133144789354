@value verticalCompensation: 2px;
@value unit: var(--ring-unit);

.downloadIcon {
  margin-right: calc(unit / 2);

  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-color);
}

.downloadLink {
  z-index: var(--ring-overlay-z-index);

  padding: 0 10px verticalCompensation;

  background-color: var(--ring-content-background-color);

  line-height: calc(unit * 3 - verticalCompensation);

  &:hover .downloadIcon,
  &:focus .downloadIcon {
    transition: none;

    color: var(--ring-icon-hover-color);
  }

  &:active .downloadIcon {
    transition: none;

    color: var(--ring-main-color);
  }
}
