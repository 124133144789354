@value unit: var(--ring-unit);

.navigation {
  position: absolute;

  z-index: 1;
  right: calc(unit * 2);
  bottom: calc(unit * 2);
}

.buttons {
  display: flex;
  flex-direction: column;

  border-radius: var(--ring-border-radius);
  box-shadow: 0 2px 16px 0 var(--ring-popup-shadow-color);
}

.button {
  width: 100%;

  &.start.start {
    border-radius: var(--ring-border-radius) var(--ring-border-radius) 0 0;
  }

  &.end.end {
    margin: -1px 0 0;

    border-radius: 0 0 var(--ring-border-radius) var(--ring-border-radius);
  }
}

.tooltip {
  padding: 1px unit 2px;

  pointer-events: none;

  color: var(--ring-text-color);
}
